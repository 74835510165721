import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import secureLocalStorage from "react-secure-storage";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [filteredItems, setFilteredItems] = useState(null);
  let categoryType = "before_after_story_People"; // eslint-disable-next-line

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      if (secureLocalStorage.getItem("gender")) {
        if (secureLocalStorage.getItem("gender") === "Female")
          categoryType = "before_after_story_female"; // eslint-disable-next-line
        if (secureLocalStorage.getItem("gender") === "Male")
          categoryType = "before_after_story_male"; // eslint-disable-next-line
      }
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType); // eslint-disable-next-line
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    goToPage(null, null, "age");
  };

  return (
    <MainBg2 navbar={2}>
      {filteredItems !== null ? (
        <>
          <Progress progress={30} />
          <div className={MAIN_DIV_CLASS}>
            <div className={TITLE_DIV_CLASS}>
              <Title1Module titledata={filteredItems} />
              <div className="max-w-[400px] w-full m-auto mt-10">
                <img
                  loading="lazy"
                  src={
                    (secureLocalStorage.getItem("gender") === "Female" &&
                      Allimages.femalegood) ||
                    (secureLocalStorage.getItem("gender") === "Male" &&
                      Allimages.malegood) ||
                    (secureLocalStorage.getItem("gender") !== "Female" &&
                      secureLocalStorage.getItem("gender") !== "Male" &&
                      Allimages.peoplegood)
                  }
                  alt="femalegood"
                  className="imgwith mb-5"
                />
                <div className="df justify-between">
                  <div className="malestar">
                    <img
                      loading="lazy"
                      src="./images/shape1.webp"
                      alt="shape1"
                    />
                    <span>{filteredItems?.title4}</span>
                  </div>
                  <div className="starts">
                    <img
                      loading="lazy"
                      src={Allimages.starts}
                      alt="rates"
                      className="deshimg"
                    />
                  </div>
                </div>
                <div className="tracname text-2xl font-medium ">
                  <span>{filteredItems?.title2}</span>
                </div>
                <div className="tex11say m-0 text-xs !mb-4">
                  <span>{filteredItems?.title3}</span>
                </div>
                <div className="tex11say text-sm m-0 mb-10">
                  <p>{filteredItems?.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={NEXT_DIV_CLASS}>
            <ContinueButton
              isdisabled={true}
              label={"Next"}
              className={"mx-auto w-9/12 !mt-0 mb-14"}
              onClick={handleGoForward}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </MainBg2>
  );
};
export default Page;
