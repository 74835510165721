import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";
import all_bottom_vector_image from "../config/all_bottom_vector_image";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [filteredItems, setFilteredItems] = useState(null);

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, "real_talk");
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems]);

  const handleGoForward = () => {
    goToPage(null, null, "journey");
  };

  return (
    <div className="relative">
      <MainBg2 navbar={2}>
        <Progress progress={75} />
        <div className={MAIN_DIV_CLASS + " relative"}>
          <div className={TITLE_DIV_CLASS}>
            <div className="spww-realtalk sm:w-[450px] m-auto">
              <div className="df1 relative real-title-div">
                <Title1Module titledata={filteredItems} />

                <div className="chat-icon-realtalk">
                  <img
                    loading="lazy"
                    src={Allimages.realtalk1}
                    alt="realtalk1"
                    className="imgwith sm:!h-[80px] !w-auto ml-0"
                  />
                </div>
              </div>
              <div className="tex11say m-0 text-base mt-5 mb-10 !text-center">
                <span>{filteredItems?.title2}</span>
              </div>
              <div className="imagesbotomesl1-realtak-mob">
                {/* max-[1440px]:left-[355px] */}
                {/* <img
                  src={all_bottom_vector_image.realtalkBottommob}
                  alt="nutrionbuttom"
                  className={"h-[293px]"}
                /> */}
              </div>
              <div className="md:mb-10 mb-36  text-center">
                <p>
                  {filteredItems?.title3?.split("@").map((item, index) => (
                    <span
                      className={
                        index === 1 ? "titlecolo !m-0 text-lg" : "tex1"
                      }
                      key={index}
                    >
                      {" "}
                      {item}{" "}
                    </span>
                  ))}
                </p>
              </div>
            </div>
            <div className={NEXT_DIV_CLASS}>
              <ContinueButton
                isdisabled={true}
                label={"Next"}
                className={"    "}
                onClick={handleGoForward}
              />
            </div>
            <div className="imagesbotomesl1-realtak  -left-[50%] top-[0%]">
              {/* max-[1440px]:left-[355px] */}
              {/* <img
                src={all_bottom_vector_image.realtalkBottom}
                alt="nutrionbuttom"
                className={"h-[500px]"}
              /> */}
            </div>
          </div>
        </div>
      </MainBg2>
    </div>
  );
};
export default Page;
