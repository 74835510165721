import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import '../style/';
import { Navigation } from "swiper/modules";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";
const getNumbersInRange = (start, end) => {
  const arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }
  return arr;
};
const Page = () => {
  const { surveyItems, validation } = useSelector(
    (state) => state.webUtilities
  );
  const goToPage = useGoToPage();
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const [filteredItems2, setFilteredItems2] = useState(null);
  const categoryType = "height";

  const heightFtMin = validation?.heightFtMin || 3;
  const heightftMax = validation?.heightftMax || 6;
  // const heightftlist = getNumbersInRange(heightFtMin, heightftMax)
  const heightInMin = 0;
  // const heightInMax = 11
  const heightftMaxnew = heightftMax * 12 + 11;
  const heightFtMinnew = heightFtMin * 12;
  const heightinlist = getNumbersInRange(heightFtMinnew, heightftMaxnew);
  const heightCmMin = validation?.heightCmMin || 135;
  const heightCmMax = validation?.heightCmMax || 213;
  const heightcmlist = getNumbersInRange(heightCmMin, heightCmMax);

  const localheightData = secureLocalStorage.getItem("height");
  const [heightStatus, setHeightStatus] = useState(
    localheightData?.cm ? "cm" : "ft"
  );
  const [heightft, setheightft] = useState(localheightData?.ft || 4);
  const [heightin, setheightin] = useState(localheightData?.inch || 1);
  const [heightcm, setheightcm] = useState(localheightData?.cm || 200);

  const Heightvlaue = {
    ft: (heightStatus === "ft" && heightft) || null,
    inch:
      heightStatus === "ft" ? (heightin === 0 ? 0 : heightin || null) : null,
    cm: (heightStatus === "cm" && heightcm) || null,
  };

  const localWeightData = secureLocalStorage.getItem("weight");

  const [weightstaus, setweightStatus] = useState(
    localWeightData?.lbs ? "lb" : "kg"
  );
  const [weightcu, setweightcu] = useState(localWeightData?.kg || 90);
  const [weightlbscu, setweightlbscu] = useState(localWeightData?.lbs || 150);
  const weightvlaue = {
    kg: (weightstaus === "kg" && weightcu) || null,
    lbs: (weightstaus === "lb" && weightlbscu) || null,
  };
  const currentWeightkgMin = validation?.currentWeightkgMin || 43;
  const currentWeightKgMax = validation?.currentWeightKgMax || 183;
  const currentWeightKglist = getNumbersInRange(
    currentWeightkgMin,
    currentWeightKgMax
  );
  const currentWeightLbMin = validation?.currentWeightLbMin || 95;
  const currentWeightLbMax = validation?.currentWeightLbMax || 403;
  const currentWeightLblist = getNumbersInRange(
    currentWeightLbMin,
    currentWeightLbMax
  );
  const [slide] = useState(heightft * 12 + heightin);

  const handleValueChange = (index) => {
    const feet = Math.floor(index / 12) + heightFtMin;
    const inches = index % 12;
    setheightft(feet);
    setheightin(inches);
  };
  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      const filtered2 = filterSurveyItemsByCategory(
        surveyItems,
        "current_weight"
      );
      setFilteredItems(filtered[0]);
      setFilteredItems2(filtered2[0]);
    }
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    if (Heightvlaue && weightvlaue) {
      secureLocalStorage.setItem("height", Heightvlaue);
      secureLocalStorage.setItem("weight", weightvlaue);
      goToPage(null, null, "weight");
    } else {
      setErrorMsg("Please select both values");
    }
  };

  return (
    <MainBg2 navbar={2}>
      {filteredItems != null && filteredItems2 !== null ? (
        <>
          <Progress progress={35} />
          <div className={MAIN_DIV_CLASS}>
            <div className={TITLE_DIV_CLASS}>
              <Title1Module titledata={filteredItems} />
              <p className="tex text-sm"> {filteredItems?.description} </p>
              {/* button for choose height type  */}
              <div className="fasweekquestion mt-5 mb-5 justify-center gap-5">
                <div
                  className={
                    filteredItems?.categoryItems[0]?.name === heightStatus
                      ? "heightbottonac heightbottontex"
                      : "heightbotton heightbottontex"
                  }
                  onClick={() =>
                    setHeightStatus(filteredItems?.categoryItems[0]?.name) ||
                    setweightStatus(filteredItems2?.categoryItems[0]?.name)
                  }
                >
                  <p>
                    {filteredItems?.categoryItems[0]?.name}
                    {""}
                  </p>
                </div>
                <div
                  className={
                    filteredItems?.categoryItems[2]?.name === heightStatus
                      ? "heightbottonac heightbottontex"
                      : "heightbotton heightbottontex"
                  }
                  onClick={() =>
                    setHeightStatus(filteredItems?.categoryItems[2]?.name) ||
                    setweightStatus(filteredItems2?.categoryItems[1]?.name)
                  }
                >
                  <p>
                    {filteredItems?.categoryItems[2]?.name}
                    {"s"}
                  </p>
                </div>
              </div>
              {heightStatus === filteredItems?.categoryItems[0]?.name ? (
                <div className="sm:mb-2 mb-1 max-w-[340px] w-full m-auto">
                  <div
                    className="glsstex"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <span className="text-6xl">{heightft}</span>{" "}
                    <span>
                      <sub className="align-sub">{heightin}</sub>
                    </span>{" "}
                  </div>
                  <div
                    className="justify-center text-black"
                    style={{
                      display: "flex",
                      justifyContent: "evenly",
                      gap: "20px",
                    }}
                  >
                    <p>{filteredItems?.categoryItems[0]?.name}</p>
                    <p className="ml-6"> inch</p>
                  </div>
                  <div>
                    <div className="linebotm1 -mb-[20%] mt-[22px]">
                      <img
                        loading="lazy"
                        src="./images/line.webp"
                        className=" max-h-[65px] h-full"
                        alt="linesmal"
                      />
                    </div>
                    <Swiper
                      modules={[Navigation]}
                      navigation={{ clickable: true }}
                      initialSlide={heightinlist.indexOf(slide)}
                      onSlideChange={(swiper) =>
                        handleValueChange(swiper.activeIndex)
                      }
                      slidesPerView={20}
                      centeredSlides={true}
                    >
                      {heightinlist.map((innerIndex) => (
                        <SwiperSlide key={innerIndex}>
                          <div>
                            <img
                              loading="lazy"
                              src="./images/linesmal.webp"
                              alt="linesmal"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="linebotm flex justify-between px-16">
                      <span className="text-gray-300 opacity-75">
                        {heightft - 1}
                      </span>
                      <span>
                        {heightft}.{heightin}
                      </span>
                      <span className="text-gray-300 opacity-75">
                        {heightft + 1}
                      </span>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              ) : (
                <div className="sm:mb-2 mb-1  max-w-[340px] w-full m-auto">
                  <div className="glsstex  text-6xl">{heightcm}</div>
                  <div className="tex">
                    <p>{filteredItems?.categoryItems[2]?.name}</p>
                  </div>
                  <div className="linebotm1 -mb-[20%] mt-[22px]">
                    <img
                      loading="lazy"
                      src="./images/line.webp"
                      className=" max-h-[65px] h-full"
                      alt="linesmal"
                    />
                  </div>
                  <Swiper
                    modules={[Navigation]}
                    navigation={{ clickable: true }}
                    initialSlide={heightcmlist.indexOf(heightcm)}
                    onSlideChange={(pre) =>
                      setheightcm(heightcmlist[pre.activeIndex])
                    }
                    slidesPerView={20}
                    centeredSlides={true}
                  >
                    {heightcmlist.map((index) => (
                      <SwiperSlide key={index}>
                        <div>
                          <img
                            loading="lazy"
                            src="./images/linesmal.webp"
                            alt="linesmal"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="linebotm flex justify-between px-16">
                    <span className="text-gray-300 opacity-75">
                      {heightcm - 10}
                    </span>
                    <span>{heightcm}</span>
                    <span className="text-gray-300 opacity-75">
                      {heightcm + 10}
                    </span>
                  </div>
                  <br />
                </div>
              )}
              <div className={NEXT_DIV_CLASS + " md:mb-24"}>
                <ContinueButton
                  isdisabled={Heightvlaue}
                  label={"Next"}
                  className={"sm:mb-28"}
                  onClick={handleGoForward}
                />
              </div>
            </div>
          </div>
          {/* <BottomImageModule imagepath={all_bottom_vector_image.selfigirl} image_variant={3} /> */}
        </>
      ) : (
        <></>
      )}
    </MainBg2>
  );
};
export default Page;
