import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";
const getNumbersInRange = (start, end) => {
  const arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }
  return arr;
};
const Page = () => {
  const { surveyItems, validation } = useSelector(
    (state) => state.webUtilities
  );
  const goToPage = useGoToPage();
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const categoryType = "weight";

  const goalWeight = secureLocalStorage.getItem("weightGoal");
  const currentWeight = secureLocalStorage.getItem("weight");
  const weighttargetstaus =
    currentWeight?.kg == (currentWeight?.kg || currentWeight?.lbs)
      ? filteredItems?.categoryItems[2]?.name
      : filteredItems?.categoryItems[3]?.name;
  const [weighttarget, setweighttarget] = useState(goalWeight?.kg || 80);
  const [weighttargetlbs, setweighttargetlbs] = useState(goalWeight?.lbs || 88);

  const tragetrweightvlaue = {
    kg: (weighttargetstaus == "kg" && weighttarget) || null,
    lbs: (weighttargetstaus == "lb" && weighttargetlbs) || null,
  };
  const targetWeightkgMin = validation?.targetWeightkgMin || 40;
  const targetWeightKgMax =
    currentWeight?.kg - validation?.currentTargetWeightKgDiffMin || 180;
  const targetWeightKglist = getNumbersInRange(
    targetWeightkgMin,
    targetWeightKgMax
  );

  // Lb calculation
  const targetWeightLbMin = validation?.targetWeightLbMin || 88;
  const targetWeightLbMax =
    currentWeight?.lbs - validation?.currentTargetWeightLbDiffMin || 396;
  const targetWeightLblist = getNumbersInRange(
    targetWeightLbMin,
    targetWeightLbMax
  );
  const gender = secureLocalStorage.getItem("gender") || "Male";

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      let prechangetitle1 = filtered[0];
      prechangetitle1 = { ...prechangetitle1, title1: filtered[0].title2 };
      setFilteredItems(prechangetitle1);
    }
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    if (tragetrweightvlaue) {
      goToPage("weightGoal", tragetrweightvlaue, "motivating");
    } else {
      setErrorMsg("Please select atleast 1 of the above");
    }
  };

  const agehandelimages = () => {
    if (gender == "Male") {
      return all_bottom_vector_image.weightgoalmale;
    } else if (gender == "Female") {
      return all_bottom_vector_image.weightgoalfemale;
    } else {
      return all_bottom_vector_image.weightgoalrathernot;
    }
  };
  console.log(goalWeight);

  return (
    <MainBg2 navbar={2}>
      {filteredItems === null ? (
        <></>
      ) : (
        <>
          <Progress progress={37} />
          <div className={MAIN_DIV_CLASS}>
            <div className={TITLE_DIV_CLASS}>
              <Title1Module titledata={filteredItems} />
              <p className="tex text-sm mb-3"> {filteredItems?.description} </p>
              {weighttargetstaus == filteredItems?.categoryItems[2]?.name ? (
                <div className="sm:mb-2 mb-1 max-w-[340px] w-full m-auto">
                  <div className="glsstex text-6xl mt-10">{weighttarget}</div>
                  <div className="tex">
                    <p>{filteredItems?.categoryItems[2]?.name}</p>
                  </div>
                  <div className="linebotm1 -mb-[20%] mt-[22px]">
                    <img
                      loading="lazy"
                      src="./images/line.webp"
                      className=" max-h-[65px] h-full"
                      alt="linesmal"
                    />
                  </div>
                  {/* initialSlide={targetWeightKglist.indexOf(weighttarget)} */}
                  <Swiper
                    modules={[Navigation]}
                    navigation={{ clickable: true }}
                    initialSlide={targetWeightKglist.indexOf(weighttarget)}
                    onSlideChange={(pre) =>
                      setweighttarget(targetWeightKglist[pre.activeIndex])
                    }
                    slidesPerView={20}
                    centeredSlides={true}
                  >
                    {targetWeightKglist.map((index) => (
                      <SwiperSlide key={index}>
                        <div>
                          <img
                            loading="lazy"
                            src="./images/linesmal.webp"
                            alt="linesmal"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="linebotm">
                    <img
                      loading="lazy"
                      src="./images/linebtom.webp"
                      alt="linebtom"
                    />
                  </div>
                </div>
              ) : (
                <div className="sm:mb-2 mb-1 max-w-[340px] w-full m-auto">
                  <div className="glsstex text-6xl mt-10">
                    {weighttargetlbs}
                  </div>
                  <div className="tex">
                    <p>{filteredItems?.categoryItems[3]?.name}s</p>
                  </div>
                  <div className="linebotm1 -mb-[20%] mt-[22px]">
                    <img
                      loading="lazy"
                      src="./images/line.webp"
                      className=" max-h-[65px] h-full"
                      alt="linesmal"
                    />
                  </div>
                  <Swiper
                    modules={[Navigation]}
                    navigation={{ clickable: true }}
                    initialSlide={targetWeightLblist.indexOf(weighttargetlbs)}
                    onSlideChange={(pre) =>
                      setweighttargetlbs(targetWeightLblist[pre.activeIndex])
                    }
                    slidesPerView={20}
                    centeredSlides={true}
                  >
                    {targetWeightLblist.map((index) => (
                      <SwiperSlide key={index}>
                        <div>
                          <img
                            loading="lazy"
                            src="./images/linesmal.webp"
                            alt="linesmal"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="linebotm">
                    {/* <img
                  loading='lazy'
                  src='./images/linebtom.webp'
                  alt='linebtom'
                /> */}
                  </div>
                </div>
              )}
              <div className={NEXT_DIV_CLASS}>
                <p className="text-md text-red-500 text-center">{errorMsg}</p>
                <ContinueButton
                  isdisabled={targetWeightKgMax}
                  label={"Next"}
                  className={""}
                  onClick={handleGoForward}
                />
              </div>
            </div>
          </div>
          <BottomImageModule imagepath={agehandelimages()} image_variant={3} />
        </>
      )}
    </MainBg2>
  );
};
export default Page;
