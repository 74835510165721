import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";
const getNumbersInRange = (start, end) => {
  const arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }
  return arr;
};
const Page = () => {
  const { surveyItems, validation } = useSelector(
    (state) => state.webUtilities
  );
  const goToPage = useGoToPage();
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const categoryType = "age";
  const ageMin = validation?.ageMin || 18;
  const ageMax = validation?.ageMax || 60;
  const people1 = getNumbersInRange(ageMin, ageMax);
  const ageess = secureLocalStorage.getItem("age") || 30;
  const gender = secureLocalStorage.getItem("gender") || "Male";
  const age = () => {
    return ageess || ageMin;
  };
  const [ageold, setAgeold] = useState(age());

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    if (ageold > 17) {
      goToPage("age", ageold, "height");
    } else {
      setErrorMsg("Please select atleast 1 of the above");
    }
  };
  console.log(filteredItems);
  const agehandelimages = () => {
    if (gender == "Male") {
      return all_bottom_vector_image.manage;
    } else if (gender == "Female") {
      return all_bottom_vector_image.womanage;
    } else {
      return all_bottom_vector_image.curiousbirdage;
    }
  };

  return (
    <MainBg2 navbar={2}>
      <Progress progress={32} />
      {filteredItems !== null ? (
        <>
          <div className={MAIN_DIV_CLASS}>
            <div className={TITLE_DIV_CLASS}>
              <Title1Module titledata={filteredItems} />
              <p className="tex text-sm"> {filteredItems?.description} </p>
              <div className="glsstex text-6xl mt-10">{ageold}</div>
              <h2 className="font-bold text-center text-sm">
                {filteredItems?.title2}
              </h2>
              <div className="sm:mb-2 mb-1 max-w-[340px] w-full m-auto">
                <div className="linebotm1 -mb-[20%] mt-[22px]">
                  <img
                    loading="lazy"
                    src="./images/line.webp"
                    className=" max-h-[65px] h-full"
                    alt="linesmal"
                  />
                </div>
                <Swiper
                  modules={[Navigation]}
                  navigation={{ clickable: true }}
                  initialSlide={people1.indexOf(ageold)}
                  onSlideChange={(pre) => setAgeold(people1[pre.activeIndex])}
                  slidesPerView={20}
                  centeredSlides={true}
                >
                  {people1.map((index) => (
                    <SwiperSlide key={index}>
                      <div>
                        <img
                          loading="lazy"
                          src="./images/linesmal.webp"
                          alt="linesmal"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="linebotm">
                  {/* <img loading='lazy' src='./images/linebtom.webp' alt='linebtom' /> */}
                </div>
              </div>
              <div className={NEXT_DIV_CLASS + " mt-10"}>
                <p className="text-md text-red-500 text-center">{errorMsg}</p>
                <ContinueButton
                  isdisabled={ageold > 17}
                  label={"Next"}
                  className={""}
                  onClick={handleGoForward}
                />
              </div>
            </div>
          </div>
          <BottomImageModule imagepath={agehandelimages()} image_variant={3} />
        </>
      ) : (
        <></>
      )}
    </MainBg2>
  );
};
export default Page;
