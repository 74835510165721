import React, { useState, useEffect } from 'react'
import { MainBg2 } from '../components/Mainbg'
import {
  filterSurveyItemsByCategory,
  storeLocalStorage,
  useGoToPage
} from '../function/functions'
import secureLocalStorage from 'react-secure-storage'
import Progress from '../components/Progress'
import { ContinueButton } from '../components/utilities'
import { useSelector } from 'react-redux'
import { Allimages } from '../config/AllImage'
import MAIN_DIV_CLASS, { GRID_LAYOUT_2, NEXT_DIV_CLASS, TITLE_DIV_CLASS } from './module/CommonClasses'
import Title1Module from './module/Title1Module'
import BottomImageModule from './module/BottomImageModule'
import all_bottom_vector_image from '../config/all_bottom_vector_image'

const Page = () => {
  const { surveyItems } = useSelector(state => state.webUtilities)
  const goToPage = useGoToPage()
  const [gender, Gender] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [filteredItems, setFilteredItems] = useState(null)
  const categoryType = 'gender'

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType)
      setFilteredItems(filtered[0])
    }
  }, [surveyItems, categoryType])

  const storeClick = async itemId => {
    setErrorMsg('')
    Gender(itemId)
    storeLocalStorage('gender', itemId)
  }

  useEffect(() => {
    if (secureLocalStorage.getItem('gender')) {
      Gender(secureLocalStorage.getItem('gender'))
    }
  }, [])

  const handleGoForward = () => {
    if (gender.length > 0) {
      goToPage('gender', gender, 'testimonial')
    } else {
      setErrorMsg('Please select atleast 1 of the above')
    }
  }

  return (
    <MainBg2 navbar={2}>
      <Progress progress={24} />
      <div className={MAIN_DIV_CLASS}>
        <div className={TITLE_DIV_CLASS}>
          <Title1Module titledata={filteredItems} />
          <div className={GRID_LAYOUT_2}>
            {filteredItems?.categoryItems?.map((item, index) => {
              if (item.name === "Rather not say") {
                return (
                  <div className={`gap-2 m-0 px-1.5 ${gender.includes(item.name) ? 'rather-no-say-ac ' : 'rather-no-say'}`} onClick={() => storeClick(item.name)} key={index} >

                    <div
                      className={gender.includes(item.name) ? 'tex1ac text-xl font-bold' : 'tex1 text-xl font-bold'}
                    >
                      <p>{item.name}</p>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className={` w-full gap-2 m-0 px-1.5 ${gender.includes(item.name) ? 'cardlyac ' : 'cardly'}`} onClick={() => storeClick(item.name)} key={index} >
                    <img loading='lazy' src={Allimages['gender' + index]} className={`${index === 2 ? 'hidden' : 'icongol'} `} alt={item.name} />
                    <div
                      className={gender.includes(item.name) ? 'tex1ac  mt-4' : 'tex1 mt-4'}
                    >
                      <p>{item.name}</p>
                    </div>
                  </div>
                )
              }

            })}
          </div>
          <div className={NEXT_DIV_CLASS}>
            <p className='text-sm text-red-500 text-center'>{errorMsg}</p>
            <ContinueButton
              isdisabled={gender.length > 0}
              label={'Next'}
              className={'mx-auto w-9/12 !mt-3 mb-14'}
              onClick={handleGoForward}
            />
          </div>
        </div>
      </div>
      <BottomImageModule imagepath={all_bottom_vector_image.gendercuriousbird} image_variant={3} />
    </MainBg2>
  )
}
export default Page
