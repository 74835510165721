import React, { useState, useEffect, useCallback } from "react";
import Loading from "../components/loading";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import { Allimages } from "../config/AllImage";
import secureLocalStorage from "react-secure-storage";
import { getAxiosWithOutToken } from "../axios/axiosObj";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import Title1Module from "./module/Title1Module";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
// import { User } from "./User"; // component display user (see detail on /example directory)

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const [isLoading] = useState(false);
  const goto = useGoToPage();
  const [userEmail, setUserEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [continueBtn, setContinueBtn] = useState(false);
  const [Dataloading, setDataLoading] = useState(false);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const [social_Cred, setSocialCred] = useState({
    google: {
      client_id:
        "1058059313158-ue6na7f4t607sthgn06cjk14anucoejr.apps.googleusercontent.com",
    },
    facebook: {
      client_id: "3387977084681044",
    },
  });

  const validateEmail = (email) => {
    // Regular expression for validating email addresses
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const REDIRECT_URI = window.location.href;

  const onLoginStart = useCallback(() => {
    alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  useEffect(() => {
    if (secureLocalStorage.getItem("userEmail")) {
      setUserEmail(secureLocalStorage.getItem("userEmail"));
      setContinueBtn(true);
    }
    if (profile != null) {
      console.log(profile);
      secureLocalStorage.setItem("userEmail", profile.email);
      setUserEmail(profile.email);
      hanldeData();
    }
  }, []);
  const [filteredItems, setFilteredItems] = useState(null);

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, "email");
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems]);

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    if (validateEmail(enteredEmail)) {
      setErrorMsg("");
      setContinueBtn(true);
      secureLocalStorage.setItem("userEmail", enteredEmail);
    } else {
      setErrorMsg("Please enter a valid email address.");
    }
    setUserEmail(enteredEmail);
  };

  if (isLoading) return <Loading />;

  const hanldeData = async () => {
    if (!continueBtn) {
      setErrorMsg("Please enter your valid email address.");
      return false;
    }
    setDataLoading(true);
    // setContinueBtn(false)
    let data = {};
    let surveyItems2 = [];
    const country = secureLocalStorage.getItem("country") || "us";
    if (country) {
      data.country = country;
    }
    if (secureLocalStorage.getItem("username")) {
      data.name = secureLocalStorage.getItem("username");
    }
    data.ip = "2001:0db8:3c4d:0015:0000:0000:1a2f:1a2b";
    data.location = "";
    data.device = "web";
    data.deviceVer = "3.4";
    data.deviceToken = "123456";
    data.email = userEmail;
    const goalItem = secureLocalStorage.getItem("goalItem");
    if (goalItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "whats_your_goal"
      )[0];

      const newItemObjPromises = filtered.categoryItems.map(async (item) => {
        if (goalItem.includes(item.name)) {
          return {
            itemId: item.itemId,
            isTrue: 1,
            isVal: item.isVal,
          };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });

      Promise.all(newItemObjPromises).then((newItemObjs) => {
        itemCategory.category = "whats_your_goal";
        itemCategory.categoryItems = newItemObjs;
        surveyItems2.push(itemCategory);
      });
    }
    const fastingItem = secureLocalStorage.getItem("fastingItem");
    if (fastingItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "have_you_heard_about_intermittent_fasting"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === fastingItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "have_you_heard_about_intermittent_fasting";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const activityItem = secureLocalStorage.getItem("activityItem");
    if (activityItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "whats_your_activity_level"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === activityItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "whats_your_activity_level";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const nutritionItem = secureLocalStorage.getItem("nutritionItem");
    if (nutritionItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "lets_talk_nutrition"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === nutritionItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "lets_talk_nutrition";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const mealItem = secureLocalStorage.getItem("mealItem");
    if (mealItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "how_many_meals_do_you_eat_per_day"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === mealItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "how_many_meals_do_you_eat_per_day";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const dietaryItem = secureLocalStorage.getItem("dietaryItem");
    if (dietaryItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "are_you_following_specific_diet"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === dietaryItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "are_you_following_specific_diet";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const allergyItem = secureLocalStorage.getItem("allergyItem");
    if (allergyItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "ingredient_allergies"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === allergyItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "ingredient_allergies";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
      console.log(itemCategory);
    }

    const waterItem = secureLocalStorage.getItem("waterItem");
    if (waterItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "what_is_your_daily_water_intake"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === waterItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "what_is_your_daily_water_intake";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const nightItem = secureLocalStorage.getItem("nightItem");
    if (nightItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "be_honest_whats_your_average_night_like"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === nightItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "be_honest_whats_your_average_night_like";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const gender = secureLocalStorage.getItem("gender");
    if (gender) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(surveyItems, "gender")[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === gender) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "gender";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const age = secureLocalStorage.getItem("age");
    if (age) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(surveyItems, "age")[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === `age`) {
          return { itemId: item.itemId, isTrue: 1, isVal: age };
        } else if (item.name === `year`) {
          return { itemId: item.itemId, isTrue: 1, isVal: null };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "age";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }

    const height = secureLocalStorage.getItem("height");
    if (height) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(surveyItems, "height")[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === "ft" && height.ft) {
          return { itemId: item.itemId, isTrue: 1, isVal: height.ft };
        } else if (item.name === "inch" && height.inch) {
          return { itemId: item.itemId, isTrue: 1, isVal: height.inch };
        } else if (item.name === "cm" && height.cm) {
          return { itemId: item.itemId, isTrue: 1, isVal: height.cm };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });

      itemCategory.category = "height";
      itemCategory.categoryItems = newItemObj;
      surveyItems2.push(itemCategory);
    }
    const weight = secureLocalStorage.getItem("weight");
    const weightGoal = secureLocalStorage.getItem("weightGoal");
    if (weight && weightGoal) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(surveyItems, "weight")[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === "weight" && (weight.kg || weight.lbs)) {
          return {
            itemId: item.itemId,
            isTrue: 1,
            isVal: weight.kg || weight.lbs,
          };
        } else if (
          item.name === "target weight" &&
          (weightGoal.kg || weightGoal.lbs)
        ) {
          return {
            itemId: item.itemId,
            isTrue: 1,
            isVal: weightGoal.kg || weightGoal.lbs,
          };
        } else if ((item.name === "lbs" || item.name === "lb") && weight.lbs) {
          return { itemId: item.itemId, isTrue: 1, isVal: null };
        } else if ((item.name === "kgs" || item.name === "kg") && weight.kg) {
          return { itemId: item.itemId, isTrue: 1, isVal: null };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });

      itemCategory.category = "weight";
      itemCategory.categoryItems = newItemObj;
      surveyItems2.push(itemCategory);
    }

    // const weightGoal = secureLocalStorage.getItem("weightGoal");
    // if (weightGoal) {
    //   const itemCategory = {};
    //   const filtered = filterSurveyItemsByCategory(
    //     surveyItems,
    //     "target_weight"
    //   )[0];
    //   const newItemObj = filtered.categoryItems.map((item) => {
    //     if (item.name === "kg" && weightGoal.kg) {
    //       return { itemId: item.itemId, isTrue: 1, isVal: weightGoal.kg };
    //     } else if (
    //       (item.name === "lbs" || item.name === "lb") &&
    //       weightGoal.lbs
    //     ) {
    //       return { itemId: item.itemId, isTrue: 1, isVal: weightGoal.lbs };
    //     } else {
    //       return {
    //         itemId: item.itemId,
    //         isTrue: item.isTrue,
    //         isVal: item.isVal,
    //       };
    //     }
    //   });

    //   itemCategory.category = "target_weight";
    //   itemCategory.categoryItems = newItemObj;
    //   surveyItems2.push(itemCategory);
    // }
    const motivationItem = secureLocalStorage.getItem("motivationItem");
    if (motivationItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "motivation_to_loose_weight"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === motivationItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "motivation_to_loose_weight";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }

    const eventDate = secureLocalStorage.getItem("eventDate");
    if (eventDate) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "sport_event_date"
      )[0];

      const newItemObj = filtered.categoryItems.map((item) => {
        let formattedDate = "";
        if (eventDate !== "null" && eventDate !== null && eventDate !== "") {
          const date = new Date(eventDate);
          formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        }
        return {
          itemId: item.itemId,
          isTrue:
            eventDate !== "null" && eventDate !== null && eventDate !== ""
              ? 1
              : item.isTrue,
          isVal: null,
        };
      });

      itemCategory.category = "sport_event_date";
      itemCategory.categoryItems = newItemObj;
      surveyItems2.push(itemCategory);
    }
    const habit = secureLocalStorage.getItem("habit");
    if (habit) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "motivation_to_loose_weight_male"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === habit && gender.toLowerCase() == "male") {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "motivation_to_loose_weight_male";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    if (habit) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "motivation_to_loose_weight_female"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === habit && gender.toLowerCase() == "female") {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "motivation_to_loose_weight_female";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    if (habit) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "motivation_to_loose_weight_people"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (
          item.name === habit &&
          gender.toLowerCase() !== "female" &&
          gender.toLowerCase() !== "male"
        ) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "motivation_to_loose_weight_people";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const bodyType = secureLocalStorage.getItem("bodyType");
    if (bodyType) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "what_is_your_body_shape_male"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === bodyType && gender.toLowerCase() == "male") {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "what_is_your_body_shape_male";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    if (bodyType) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "what_is_your_body_shape_female"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === bodyType && gender.toLowerCase() == "female") {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "what_is_your_body_shape_female";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    if (bodyType) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "what_is_your_body_shape_people"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (
          item.name === bodyType &&
          gender.toLowerCase() !== "female" &&
          gender.toLowerCase() !== "male"
        ) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "what_is_your_body_shape_people";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const habitItem = secureLocalStorage.getItem("habitItem");
    if (habitItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "eating_habits"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === habitItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "eating_habits";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const emotionItem = secureLocalStorage.getItem("emotionItem");
    if (emotionItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "eating_deal_with_emotions"
      )[0];
      const newItemObj = filtered.categoryItems.map((item, index) => {
        if (index + 1 === emotionItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "eating_deal_with_emotions";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const afterWeekItem = secureLocalStorage.getItem("afterWeekItem");
    if (afterWeekItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "eating_deal_with_afterweek"
      )[0];
      const newItemObj = filtered.categoryItems.map((item, index) => {
        if (index + 1 === afterWeekItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "eating_deal_with_afterweek";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const peopleRoundItem = secureLocalStorage.getItem("peopleRoundItem");
    if (peopleRoundItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "eating_deal_with_peopleround"
      )[0];
      const newItemObj = filtered.categoryItems.map((item, index) => {
        if (index + 1 === peopleRoundItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "eating_deal_with_peopleround";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const multiTaskingItem = secureLocalStorage.getItem("multiTaskingItem");
    if (multiTaskingItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "eating_deal_with_multasking"
      )[0];
      const newItemObj = filtered.categoryItems.map((item, index) => {
        if (index + 1 === multiTaskingItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "eating_deal_with_multasking";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const myPlateItem = secureLocalStorage.getItem("myPlateItem");
    if (myPlateItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "eating_deal_with_myplate"
      )[0];
      const newItemObj = filtered.categoryItems.map((item, index) => {
        if (index + 1 === myPlateItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "eating_deal_with_myplate";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const journeyItem = secureLocalStorage.getItem("journeyItem");
    if (journeyItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "fastbetter_journey_feeling"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === journeyItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "fastbetter_journey_feeling";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const ilikeItem = secureLocalStorage.getItem("ilikeItem");
    if (ilikeItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "with_fastbetter_i_did_like_to"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === ilikeItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "with_fastbetter_i_did_like_to";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const mySelfItem = secureLocalStorage.getItem("mySelfItem");
    if (mySelfItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "i_see_myself"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === mySelfItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "i_see_myself";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const prioritiesItem = secureLocalStorage.getItem("prioritiesItem");
    if (prioritiesItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "your_current_priorities"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === prioritiesItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "your_current_priorities";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const gainweightItem = secureLocalStorage.getItem("gainweightItem");
    if (gainweightItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "have_gained_weight_due_to_life_events"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === gainweightItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "have_gained_weight_due_to_life_events";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const diagnosedItem = secureLocalStorage.getItem("diagnosedItem");
    if (diagnosedItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "have_you_diagnosed_health_condition"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === diagnosedItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category = "have_you_diagnosed_health_condition";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }
    const holisticItem = secureLocalStorage.getItem("holisticItem");
    if (holisticItem) {
      const itemCategory = {};
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "holistic_approach_goes_beyond_weight_loss_mood_health"
      )[0];
      const newItemObj = filtered.categoryItems.map((item) => {
        if (item.name === holisticItem) {
          return { itemId: item.itemId, isTrue: 1, isVal: item.isVal };
        } else {
          return {
            itemId: item.itemId,
            isTrue: item.isTrue,
            isVal: item.isVal,
          };
        }
      });
      itemCategory.category =
        "holistic_approach_goes_beyond_weight_loss_mood_health";
      itemCategory.categoryItems = await newItemObj;
      surveyItems2.push(itemCategory);
    }

    data.surveyItems = await surveyItems2;
    // console.log(data.surveyItems)
    // return false
    try {
      const response = await getAxiosWithOutToken({
        method: "POST",
        url: "/pre/preregister",
        data: data,
      });
      if (response && response.data) {
        if (response && response.data && response.data.responce) {
          goto("finalData", response.data.responce, "priceplan");
        }
      } else {
        setErrorMsg(response.data.error.message);
      }
    } catch (error) {
      setErrorMsg(error.response.data.error.message);
    }
    setDataLoading(false);
    setContinueBtn(true);
  };

  return (
    <div className="relative ">
      <MainBg2 body_position={""}>
        <Progress progress={100} />
        <div className="w-full sm:mt-28 mt-20 mb-14 email-mob">
          <Title1Module titledata={filteredItems} />
          <p className="tex text-sm">We’ll send you an email ...</p>
          <div className="flex justify-center">
            <div className="w-4/6 ">
              <input
                type="email"
                name="email"
                value={userEmail || ""}
                onChange={handleEmailChange}
                placeholder="your@email.com"
                className="inputbox mt-5 sm:text-base sm:px-4 sm:py-4 py-3 px-4 text-sm rounded-xl"
              />
              <div className="cardl22l my-4">
                <div className="loginde" />
                <div className="tex !text-xs font-normal">
                  <p>Or link it with</p>
                </div>
                <div className="loginde" />
              </div>
              <div className="cardl22l mb-6">
                {/* <div className="loginbox sm:mx-3 mx-4 sm:py-3 py-2.5 sm:rounded-xl border-none">
                  <img
                    loading="lazy"
                    src="./images/googel.webp"
                    className="sm:w-5 w-3 h-auto"
                    alt="facebokk"
                  />
                  <div className="logintex1">
                    <p className="sm:text-lg text-base">Google</p>
                  </div>
                </div>
                <div className="loginbox sm:mx-3 mx-4 sm:py-3 py-2.5 sm:rounded-xl border-none">
                  <img
                    loading="lazy"
                    src="./images/facebook.webp"
                    alt="facebokk"
                    className="sm:w-3 w-2 h-auto"
                  />
                  <div className="logintex1">
                    <p className="sm:text-lg text-base">Facebook</p>
                  </div>
                </div> */}

                {/* <LoginSocialFacebook
                  isOnlyGetToken={false}
                  appId={social_Cred.facebook.client_id || ""}
                  onLoginStart={onLoginStart}
                  onResolve={({ provider, data }) => {
                    setProvider(provider);
                    setProfile(data);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <FacebookLoginButton style={{ fontSize: "12px" }} />
                </LoginSocialFacebook>

                <LoginSocialGoogle
                  isOnlyGetToken={false}
                  client_id={social_Cred.google.client_id || ""}
                  onLoginStart={onLoginStart}
                  onResolve={({ provider, data }) => {
                    setProvider(provider);
                    setProfile(data);
                    setContinueBtn(true);
                    secureLocalStorage.setItem("userEmail", data.email);
                    setUserEmail(data.email);
                    hanldeData();
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <GoogleLoginButton style={{ fontSize: "12px" }} />
                </LoginSocialGoogle> */}
              </div>
            </div>
          </div>
          <p className="text-sm text-red-500 text-center">{errorMsg}</p>
          <ContinueButton
            isdisabled={continueBtn ? "disabled" : ""}
            label={"Next"}
            className={""}
            onClick={hanldeData}
          />
          {/* <div className='btmain w-11/12 mt-3 mx-auto text-center'>
          <div
            onClick={hanldeData}
            className={`sm:w-full w-10/12 mx-auto font-medium text-xl bottontex bottonly sm:py-4 py-3 ${continueBtn ? 'bottonlyac' : 'bg-gray-300'
              }`}
          >
            Next
          </div>
        </div> */}
        </div>
      </MainBg2>
    </div>
  );
};
export default Page;
