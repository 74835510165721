import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import { NEXT_DIV_CLASS } from "./module/CommonClasses";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [filteredItems, setFilteredItems] = useState(null);
  const categoryType = "what_is_intermittent_fasting";

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    goToPage(null, null, "activity");
  };

  return (
    <MainBg2 navbar={2}>
      {filteredItems !== null ? (
        <>
          <Progress progress={9} />
          <div className="flex items-center content-center place-content-center">
            {/* <div className='mc1 w-full mb-5 mt-2'> */}
            <div className="mc1 w-full overflow-x-hidden mt-8 max-w-[410px] w-full">
              <div className="df1">
                <div className="">
                  <div>
                    <h1 className="malestar1 mt-5">
                      {filteredItems?.title1?.split("@").map((item, index) => (
                        <span
                          className={`sm:text-2xl text-2xl ${
                            index === 1 ? "titlecolo" : "title"
                          }`}
                          key={index}
                        >
                          {item}
                        </span>
                      ))}
                    </h1>
                  </div>
                  <div className="tex1">
                    <p className="text-sm">{filteredItems?.title2}</p>
                  </div>
                </div>
                <div className=" hidden fatsingtextimg">
                  <img src={Allimages.fastingabout} alt="fastingabout" />
                </div>
              </div>
              <div>
                <h1 className="malestar1 mt-8">
                  {filteredItems?.title3?.split("@").map((item, index) => (
                    <span
                      className={`sm:text-2xl text-2xl ${
                        index === 1 ? "titlecolo" : "title"
                      }`}
                      key={index}
                    >
                      {item}
                    </span>
                  ))}
                </h1>
              </div>
              <div className="tex1">
                <p className="text-sm">{filteredItems?.title4}</p>
              </div>
              <div>
                <h1 className="malestar1 !mb-3 !mt-8">
                  {filteredItems?.title5?.split("@").map((item, index) => (
                    <span
                      className={`sm:text-2xl text-2xl ${
                        index === 1 ? "thankbuttontex" : "bottontex"
                      }`}
                      key={index}
                    >
                      {item}
                    </span>
                  ))}
                </h1>
              </div>
              <div className="cardl2l mt-5 mb-3 gap-5 items-baseline	">
                <div className="col-sm-3">
                  <div className="bg-white p-5 fasting-abt-shdw ">
                    <img
                      loading="lazy"
                      src={Allimages.surgery3}
                      className="fastaboutim  mx-auto"
                      alt="surgery3"
                    />
                  </div>
                  <div className="spcim1" />
                  <div className="fastabouttextt  text-center">
                    <p>Fat burning</p>
                  </div>
                </div>
                <div className="col-sm-3 text-center">
                  <div className="bg-white p-5 fasting-abt-shdw ">
                    <img
                      loading="lazy"
                      src={Allimages.glucometer2}
                      className="fastaboutim mx-auto"
                      alt="glucometer2"
                    />
                  </div>
                  <div className="spcim1" />
                  <div className="fastabouttextt  text-center">
                    <p>
                      Lower blood
                      <br /> sugar{" "}
                    </p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="bg-white p-5 fasting-abt-shdw">
                    <img
                      loading="lazy"
                      src={Allimages.health2}
                      className="fastaboutim  mx-auto"
                      alt="health2"
                    />
                  </div>
                  <div className="spcim1" />
                  <div className="fastabouttextt  text-center">
                    <p>Longer life</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={NEXT_DIV_CLASS}>
            <ContinueButton
              isdisabled={true}
              label={"Next"}
              className={"mx-auto w-9/12 mt-0 mb-14"}
              onClick={handleGoForward}
            />
          </div>
          <BottomImageModule
            imagepath={all_bottom_vector_image.FastingAbout}
            image_variant={3}
          />
        </>
      ) : (
        <></>
      )}
    </MainBg2>
  );
};
export default Page;
