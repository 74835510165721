const BottomImageModule = ({ imagepath, image_variant }) => {
    if (image_variant === 1) {
        return (
            <div className='imagesbotomesl '>
                {/* <img src={imagepath} alt='genie' className='' /> */}
            </div>
        )
    } else if (image_variant === 2) {
        return (
            <div className='imagesbotomesl  '>
                {/* <img src={imagepath} alt='genie' className='' /> */}
            </div>
        )
    }
    else if (image_variant === 3) {
        return (
            <div className='imagesbotomesl  '>
                {/* <img src={imagepath} alt='genie' className='' /> */}
            </div>
        )
    }
    else {
        return (
            <div className='imagesbotomesl'>
                {/* <img src={imagepath} alt='genie' className='' /> */}
            </div>
        )
    }
}
export default BottomImageModule;