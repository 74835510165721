import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import Roundy from "roundy";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [afterWeekItem, setAfterWeekItem] = useState(1);
  const [afterWeekList, setAfterWeekList] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "eating_deal_with_afterweek"
      );
      setFilteredItems(filtered[0]);
      const newArray = filtered[0]?.categoryItems?.map((item) => {
        // Remove the leading number and space from the name
        const newName = item.name.replace(/^\d+\s*/, "");
        return newName;
      });
      setAfterWeekList(newArray);
    }
  }, [surveyItems]);

  const RoundyAnimation = () => {
    const afterWeekItem = [1, 2, 3, 4, 5, 4, 3]; // Define the sequence of emotions
    let index = 0;

    const interval = setInterval(() => {
      setAfterWeekItem(afterWeekItem[index]);
      index++;

      if (index === afterWeekItem.length) {
        clearInterval(interval); // Stop the interval when all emotions are set
      }
    }, 200); // Delay in milliseconds between each setEmotionItem call

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (secureLocalStorage.getItem("afterWeekItem")) {
      setAfterWeekItem(secureLocalStorage.getItem("afterWeekItem"));
    }
    RoundyAnimation();
  }, []);

  const handleGoForward = () => {
    if (afterWeekItem !== "") {
      goToPage("afterWeekItem", afterWeekItem, "peopleround");
    } else {
      setErrorMsg("Please select atleast 1 of the above");
    }
  };

  return (
    <MainBg2 navbar={2}>
      {filteredItems !== null ? (
        <>
          <Progress progress={58} />
          <div className={MAIN_DIV_CLASS}>
            <div className={TITLE_DIV_CLASS + "  roundy-main-div"}>
              <Title1Module titledata={filteredItems} />
              <p className="tex text-sm"> {filteredItems?.description} </p>
              <div
                className="tex mt-10 relative  opacity-100	"
                height={300}
                width={150}
              >
                <Roundy
                  value={0 + afterWeekItem}
                  min={1}
                  max={5}
                  height={150}
                  stepSize={0}
                  stroke="#ff9950"
                  radius={150}
                  color="#ff9950"
                  bgColor="#fff"
                  allowClick={true}
                  arcSize={180}
                  style={{
                    cursor: "pointer",
                  }}
                  overrideStyle={`
              .sliderHandle {
                top: 47%;
              }
              .sliderHandle:after{
                background: linear-gradient(to top, #fb9a58, #fffcfb);
                border: 1px solid #fb9a58;
                top: -10px;
                box-shadow: 0 0 2px 2px #fb9a58;
              }
              .sliderHandle:after:hover{
                background:fb9a58 !important;
              }
              .sliderHandle:after{
                width: 40px;
                height: 40px;
              }
              .sliderHandle {
                top: 147px;
                left: 150px;
            }
            .sliderHandle:hover:after {
              box-shadow: 0 0 10px rgb(251 154 88);
          }
              
            `}
                  onChange={(value1) => setAfterWeekItem(value1)}
                />
                <div className="semi-progres-circle">
                  <div className="textstydemo text-7xl">{afterWeekItem}</div>
                  <div className="textsubstydemo text-base font-medium">
                    {afterWeekList[afterWeekItem - 1]}
                  </div>
                </div>
              </div>
              <div className={NEXT_DIV_CLASS + " pt-24"}>
                <ContinueButton
                  isdisabled={afterWeekItem !== ""}
                  label={"Next"}
                  className={"mx-auto w-9/12 !mt-1 !mb-14"}
                  onClick={handleGoForward}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </MainBg2>
  );
};
export default Page;
