import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import '../style/';
import { Navigation } from "swiper/modules";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";
const getNumbersInRange = (start, end) => {
  const arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }
  return arr;
};
const Page = () => {
  const { surveyItems, validation } = useSelector(
    (state) => state.webUtilities
  );
  const goToPage = useGoToPage();
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const [filteredItems2, setFilteredItems2] = useState(null);
  const categoryType = "weight";

  const localWeightData = secureLocalStorage.getItem("weight");

  const [weightstaus, setweightStatus] = useState(
    localWeightData?.lbs ? "lb" : "kg"
  );
  const [weightcu, setweightcu] = useState(localWeightData?.kg || 90);
  const [weightlbscu, setweightlbscu] = useState(localWeightData?.lbs || 150);
  const weightvlaue = {
    kg: (weightstaus === "kg" && weightcu) || null,
    lbs: (weightstaus === "lb" && weightlbscu) || null,
  };
  const currentWeightkgMin = validation?.currentWeightkgMin || 43;
  const currentWeightKgMax = validation?.currentWeightKgMax || 183;
  const currentWeightKglist = getNumbersInRange(
    currentWeightkgMin,
    currentWeightKgMax
  );
  const currentWeightLbMin = validation?.currentWeightLbMin || 95;
  const currentWeightLbMax = validation?.currentWeightLbMax || 403;
  const currentWeightLblist = getNumbersInRange(
    currentWeightLbMin,
    currentWeightLbMax
  );

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      const filtered2 = filterSurveyItemsByCategory(surveyItems, "weight");
      setFilteredItems(filtered[0]);
      setFilteredItems2(filtered2[0]);
    }
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    if (weightvlaue) {
      secureLocalStorage.setItem("weight", weightvlaue);
      goToPage(null, null, "weightgoal");
    } else {
      setErrorMsg("Please select both values");
    }
  };

  return (
    <MainBg2 navbar={2}>
      {filteredItems != null && filteredItems2 !== null ? (
        <>
          <Progress progress={35} />
          <div className={MAIN_DIV_CLASS}>
            <div className={TITLE_DIV_CLASS}>
              <Title1Module titledata={filteredItems} />
              <p className="tex text-sm"> {filteredItems?.description} </p>
              <div className="fasweekquestion mt-5 mb-5 justify-center gap-5">
                <div
                  className={
                    filteredItems?.categoryItems[2]?.name === weightstaus
                      ? "heightbottonac heightbottontex"
                      : "heightbotton heightbottontex"
                  }
                  onClick={() =>
                    setweightStatus(filteredItems2?.categoryItems[2]?.name)
                  }
                >
                  <p>
                    {filteredItems2?.categoryItems[2]?.name}
                    {""}
                  </p>
                </div>
                <div
                  className={
                    filteredItems?.categoryItems[3]?.name === weightstaus
                      ? "heightbottonac heightbottontex"
                      : "heightbotton heightbottontex"
                  }
                  onClick={() =>
                    setweightStatus(filteredItems2?.categoryItems[3]?.name)
                  }
                >
                  <p>
                    {filteredItems2?.categoryItems[3]?.name}
                    {"s"}
                  </p>
                </div>
              </div>
              {weightstaus === filteredItems?.categoryItems[2]?.name ? (
                <div className="sm:mb-2 mb-1 max-w-[340px] w-full m-auto">
                  <div className="glsstex text-6xl">{weightcu}</div>
                  <div className="text-center">
                    <p>{filteredItems2?.categoryItems[2]?.name}</p>
                  </div>
                  <div className="linebotm1 -mb-[20%] mt-[22px]">
                    <img
                      loading="lazy"
                      src="./images/line.webp"
                      className=" max-h-[65px] h-full"
                      alt="linesmal"
                    />
                  </div>
                  <Swiper
                    modules={[Navigation]}
                    navigation={{ clickable: true }}
                    initialSlide={currentWeightKglist.indexOf(weightcu)}
                    onSlideChange={(pre) =>
                      setweightcu(currentWeightKglist[pre.activeIndex])
                    }
                    slidesPerView={20}
                    centeredSlides={true}
                  >
                    {currentWeightKglist.map((index) => (
                      <SwiperSlide key={index}>
                        <div>
                          <img
                            loading="lazy"
                            src="./images/linesmal.webp"
                            alt="linesmal"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="linebotm flex justify-between px-16 line-pb-640">
                    <span className="text-gray-300 opacity-75">
                      {weightcu - 10}
                    </span>
                    <span>{weightcu}</span>
                    <span className="text-gray-300 opacity-75">
                      {weightcu + 10}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="sm:mb-2 mb-1  max-w-[340px] w-full m-auto">
                  <div className="sptt">
                    <div className="glsstex text-6xl">{weightlbscu}</div>
                    <div className="tex">
                      <p>{filteredItems2?.categoryItems[3]?.name}s</p>
                    </div>
                    <div className="linebotm1 -mb-[20%] mt-[22px]">
                      <img
                        loading="lazy"
                        src="./images/line.webp"
                        className=" max-h-[65px] h-full"
                        alt="linesmal"
                      />
                    </div>
                    <Swiper
                      modules={[Navigation]}
                      navigation={{ clickable: true }}
                      initialSlide={currentWeightLblist.indexOf(weightlbscu)}
                      onSlideChange={(pre) =>
                        setweightlbscu(currentWeightLblist[pre.activeIndex])
                      }
                      slidesPerView={20}
                      centeredSlides={true}
                    >
                      {currentWeightLblist.map((index) => (
                        <SwiperSlide key={index}>
                          <div>
                            <img
                              loading="lazy"
                              src="./images/linesmal.webp"
                              alt="linesmal"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="linebotm flex justify-between px-16 line-pb-640">
                      <span className="text-gray-300 opacity-75">
                        {weightlbscu - 10}
                      </span>
                      <span>{weightlbscu}</span>
                      <span className="text-gray-300 opacity-75">
                        {weightlbscu + 10}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className={NEXT_DIV_CLASS + " md:mb-24"}>
                <ContinueButton
                  isdisabled={weightstaus}
                  label={"Next"}
                  className={"sm:mb-28"}
                  onClick={handleGoForward}
                />
              </div>
            </div>
          </div>
          {/* <BottomImageModule imagepath={all_bottom_vector_image.selfigirl} image_variant={3} /> */}
        </>
      ) : (
        <></>
      )}
    </MainBg2>
  );
};
export default Page;
