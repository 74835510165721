import Footer from "../components/Footer";
import Navbar, { Navbar2a } from "../components/Navbar";
import { MAIN_MIDDLE_Bodly, MAIN_NORMAL_Bodly } from "../pages/module/CommonClasses";

const MainBg = ({ children }) => {
  return (
    <div className="manlay bg-white">
      <div className="bodyly_large">
        {children}
        <Footer />
      </div>
    </div>
  );
};
export default MainBg;

export const MainBg2 = ({ children, navbar = 1, body_position }) => {
  return (
    <div className="manlay">
      {navbar === 1 && <Navbar />}
      {navbar === 2 && <Navbar2a />}
      <div className={body_position === "middle" ? MAIN_MIDDLE_Bodly : MAIN_NORMAL_Bodly}>{children}</div>
      <Footer />
    </div>
  );
};
