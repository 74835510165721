import React, { useState, useEffect } from 'react'
import { MainBg2 } from '../components/Mainbg'
import {
  filterSurveyItemsByCategory,
  storeLocalStorage,
  useGoToPage
} from '../function/functions'
import secureLocalStorage from 'react-secure-storage'
import Progress from '../components/Progress'
import { ContinueButton } from '../components/utilities'
import { useSelector } from 'react-redux'
import { Allimages } from '../config/AllImage'
import MAIN_DIV_CLASS, { GRID_LAYOUT_3, NEXT_DIV_CLASS, TITLE_DIV_CLASS } from './module/CommonClasses'
import Title1Module from './module/Title1Module'
import BottomImageModule from './module/BottomImageModule'
import all_bottom_vector_image from '../config/all_bottom_vector_image'

const Page = () => {
  const { surveyItems } = useSelector(state => state.webUtilities)
  const goToPage = useGoToPage()
  const [holisticItem, setHolisticItem] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [filteredItems, setFilteredItems] = useState(null)

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        'holistic_approach_goes_beyond_weight_loss_mood_health'
      )
      setFilteredItems(filtered[0])
    }
  }, [surveyItems])

  const storeClick = async itemId => {
    setErrorMsg('')
    setHolisticItem(itemId)
    storeLocalStorage('holisticItem', itemId)
  }

  useEffect(() => {
    if (secureLocalStorage.getItem('holisticItem')) {
      setHolisticItem(secureLocalStorage.getItem('holisticItem'))
    }
  }, [])

  const handleGoForward = () => {
    if (holisticItem !== '') {
      goToPage('holisticItem', holisticItem, 'goodhand')
    } else {
      setErrorMsg('Please select atleast 1 of the above')
    }
  }

  return (
    <MainBg2 navbar={2}>
      <Progress progress={98} />
      <div className={MAIN_DIV_CLASS}>
        <div className={TITLE_DIV_CLASS}>
          <Title1Module titledata={filteredItems} />
          <p className='tex text-sm'> {filteredItems?.description} </p>
          <div className={GRID_LAYOUT_3}>
            {filteredItems?.categoryItems?.map((item, index) => {
              return (
                <div
                  className={`gap-2 m-0 ${holisticItem.includes(item.name)
                    ? 'cardlyac'
                    : 'cardly '
                    } mb-2`}
                  onClick={() => storeClick(item.name)}
                  key={index}
                >
                  <div className='w-[85px] flex-col'>
                    <img
                      loading='lazy'
                      src={Allimages['holistic' + index]}
                      className='icongol m-auto'
                      alt={item.name}
                    />
                    <div
                      className={
                        holisticItem.includes(item.name) ? 'tex1ac mt-4' : 'tex1 mt-4'
                      }
                    >
                      <p>{item.name}</p>
                    </div>
                  </div>

                </div>
              )
            })}

          </div>
          <div className={NEXT_DIV_CLASS + " sm:mb-20"}>
            <p className='text-sm text-red-500 text-center'>{errorMsg}</p>
            <ContinueButton
              isdisabled={holisticItem.length > 2}
              label={'Next'}
              className={''}
              onClick={handleGoForward}
            />
          </div>
        </div>
      </div>
      <BottomImageModule imagepath={all_bottom_vector_image.holistic} image_variant={3} />
    </MainBg2>
  )
}
export default Page
