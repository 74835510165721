import React, { useState, useEffect } from 'react'
import { MainBg2 } from '../components/Mainbg'
import { filterSurveyItemsByCategory, useGoToPage } from '../function/functions'
import Progress from '../components/Progress'
import { ContinueButton } from '../components/utilities'
import { useSelector } from 'react-redux'
import { Allimages } from '../config/AllImage'
import secureLocalStorage from 'react-secure-storage'
import MAIN_DIV_CLASS, { NEXT_DIV_CLASS, TITLE_DIV_CLASS } from './module/CommonClasses'
import Title1Module from './module/Title1Module'

const Page = () => {
  const { surveyItems } = useSelector(state => state.webUtilities)
  const goToPage = useGoToPage()
  const [filteredItems, setFilteredItems] = useState(null)
  let categoryType = 'motivation_to_loose_weight_people' // eslint-disable-next-line
  const [selectedHabit, setSelectedHabit] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      if (secureLocalStorage.getItem('gender')) {
        if (secureLocalStorage.getItem('gender') === 'Female')
          categoryType = 'motivation_to_loose_weight_female' // eslint-disable-next-line
        if (secureLocalStorage.getItem('gender') === 'Male')
          categoryType = 'motivation_to_loose_weight_male' // eslint-disable-next-line
      }
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType) // eslint-disable-next-line
      setFilteredItems(filtered[0])
    }
  }, [surveyItems, categoryType])

  const handleGoForward = () => {
    if (selectedHabit !== '') {
      goToPage('habit', selectedHabit, 'bodytype')
    } else {
      setErrorMsg('Please select atleast 1 of the above')
    }
  }
  useEffect(() => {
    if (secureLocalStorage.getItem('habit')) {
      setSelectedHabit(secureLocalStorage.getItem('habit'))
    }
  }, [])

  return (
    <MainBg2 navbar={2}>
      <Progress progress={47} />
      <div className={MAIN_DIV_CLASS}>
        <div className={TITLE_DIV_CLASS}>
          <div className='spcim1' />
          <div>
            <Title1Module titledata={filteredItems} />
          </div>
          <p className='tex text-sm mt-0 mb-12'>
            {filteredItems?.description}{' '}
          </p>
          <div className='mt-5 sm:mb-10 mb-0 gap-2 sm:px-32'>
            {filteredItems?.categoryItems?.map((item, index) => {
              return (
                <div
                  className={`p-4 ${item.name === selectedHabit ? 'boxac !border-[#fe7701]' : 'box border border-white'
                    }`}
                  onClick={() => {
                    setSelectedHabit(item?.name)
                    setErrorMsg('')
                  }}
                  key={index}
                >
                  <div className={`${item.name === selectedHabit ? 'border border-orange-500 bg-[#ffede0] ' : ' bg-gray-100 border border-white'} checkbox w-12 h-8`}>
                    <img
                      loading='lazy'
                      src={
                        item.name === selectedHabit ? './images/check.webp' : ''
                      }
                      alt={item.name === selectedHabit ? 'check' : ''}
                      className={item.name === selectedHabit ? '' : 'hidden'}
                    />
                  </div>
                  <p className='text-base font-normal'>{item?.name}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className={NEXT_DIV_CLASS}>
        <p className='text-sm text-red-500 text-center'>{errorMsg}</p>
        <ContinueButton
          isdisabled={selectedHabit !== ''}
          label={'Next'}
          className={'mx-auto w-9/12 !mt-3 mb-14'}
          onClick={handleGoForward}
        />
      </div>
    </MainBg2>
  )
}
export default Page
