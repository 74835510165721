// features/userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosWithOutToken } from "../../axios/axiosObj";
import secureLocalStorage from "react-secure-storage";

const initialState = {
    loading: false,
    pageError: false,
    genderRaw: secureLocalStorage.getItem('genderRaw') ? secureLocalStorage.getItem('genderRaw') : 'men',
    companyAddress: secureLocalStorage.getItem('companyAddress') ? secureLocalStorage.getItem('companyAddress') : null,
    country: secureLocalStorage.getItem('country') ? secureLocalStorage.getItem('country') : null,
    surveyCategory: secureLocalStorage.getItem('surveyCategory') ? secureLocalStorage.getItem('surveyCategory') : null,
    surveyCategoryv3: secureLocalStorage.getItem('surveyCategoryv3') ? secureLocalStorage.getItem('surveyCategoryv3') : null,
    surveyItems: secureLocalStorage.getItem('surveyItems') ? secureLocalStorage.getItem('surveyItems') : null,
    validation: secureLocalStorage.getItem('validation') ? secureLocalStorage.getItem('validation') : null,
};

export const getWebUtilities = createAsyncThunk(
    'webUtilities/getWebUtilities',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getAxiosWithOutToken({
                method: 'POST',
                url: '/pre/survey',
                data: data,
            });
            return response.data.survey;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const webUtilitiesSlice = createSlice({
    name: 'webUtilities',
    initialState,
    reducers: {
        setGenderRaw: (state, action) => {
            state.genderRaw = action.payload;
            secureLocalStorage.setItem('genderRaw', action.payload.toLowerCase());
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setPageError: (state, action) => {
            state.pageError = action.payload;
        },
        setCompanyAddress: (state, action) => {
            secureLocalStorage.setItem('companyAddress', action.payload)
            state.companyAddress = action.payload;
        },
        setCountry: (state, action) => {
            secureLocalStorage.setItem('country', action.payload)
            state.country = action.payload;
        },
        setSurveyCategory: (state, action) => {
            secureLocalStorage.setItem('surveyCategory', action.payload)
            state.surveyCategory = action.payload;
        },
        setSurveyCategoryV3: (state, action) => {
            secureLocalStorage.setItem('surveyCategoryv3', action.payload)
            state.surveyCategoryv3 = action.payload;
        },
        setSurveyItems: (state, action) => {
            secureLocalStorage.setItem('surveyItems', action.payload)
            state.surveyItems = action.payload;
        },
        setValidation: (state, action) => {
            secureLocalStorage.setItem('validation', action.payload)
            state.validation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWebUtilities.pending, (state) => {
                state.loading = true;
            })
            .addCase(getWebUtilities.fulfilled, (state, action) => {
                state.loading = false;
                state.companyAddress = action.payload.company_address;
                secureLocalStorage.setItem('companyAddress', action.payload.company_address)
                state.country = action.payload.country;
                secureLocalStorage.setItem('country', action.payload.country)
                state.surveyCategory = action.payload.surveyCategory;
                secureLocalStorage.setItem('surveyCategory', action.payload.surveyCategory)
                state.surveyCategoryv3 = action.payload.surveyCategoryv3;
                secureLocalStorage.setItem('surveyCategoryv3', action.payload.surveyCategoryv3)
                state.surveyItems = action.payload.surveyItems;
                secureLocalStorage.setItem('surveyItems', action.payload.surveyItems)
                state.validation = action.payload.validation;
                secureLocalStorage.setItem('validation', action.payload.validation)
            })
            .addCase(getWebUtilities.rejected, (state) => {
                state.loading = false;
                state.pageError = true;
            });
    },
});

// Export actions and selector
// Export actions and selector
export const {
    setLoading,
    setPageError,
    setCompanyAddress,
    setCountry,
    setSurveyCategory,
    setSurveyCategoryV3,
    setSurveyItems,
    setValidation,
    setGenderRaw
} = webUtilitiesSlice.actions;
export const selectWebUtilities = (state) => state.webUtilities;
export default webUtilitiesSlice.reducer;
