import React, { useState, useEffect } from 'react'
import { MainBg2 } from '../components/Mainbg'
import {
  filterSurveyItemsByCategory,
  storeLocalStorage,
  useGoToPage
} from '../function/functions'
import secureLocalStorage from 'react-secure-storage'
import Progress from '../components/Progress'
import { ContinueButton } from '../components/utilities'
import { useSelector } from 'react-redux'
import { Allimages } from '../config/AllImage'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import MAIN_DIV_CLASS, { GRID_LAYOUT_3, NEXT_DIV_CLASS, TITLE_DIV_CLASS } from './module/CommonClasses'
import Title1Module from './module/Title1Module'
import BottomImageModule from './module/BottomImageModule'
import all_bottom_vector_image from '../config/all_bottom_vector_image'
import popcutlayout from '../style/images/pop-cut-layout2.png'

const Page = () => {
  const { surveyItems } = useSelector(state => state.webUtilities)
  const goToPage = useGoToPage()
  const [diagnosedItem, setDiagnosedItem] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [image, setImage] = useState('')
  const [filteredItems, setFilteredItems] = useState(null)
  const [popUpModel, setPopUpModel] = useState(false)

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        'have_you_diagnosed_health_condition'
      )
      setFilteredItems(filtered[0])
    }
  }, [surveyItems])

  const storeClick = async (itemId, image) => {
    setErrorMsg('')
    setDiagnosedItem(itemId)
    setImage(image)
    storeLocalStorage('diagnosedItem', itemId)
  }

  useEffect(() => {
    if (secureLocalStorage.getItem('diagnosedItem')) {
      setDiagnosedItem(secureLocalStorage.getItem('diagnosedItem'))
    }
  }, [])

  const handleGoForward = () => {
    if (diagnosedItem !== '') {
      goToPage('diagnosedItem', diagnosedItem, 'holistic')
    } else {
      setErrorMsg('Please select atleast 1 of the above')
    }
  }

  return (
    <MainBg2 navbar={2}>
      <Progress progress={98} />
      <div className={MAIN_DIV_CLASS}>
        <div className={TITLE_DIV_CLASS}>
          <Title1Module titledata={filteredItems} />
          <p className='tex text-sm mt-0'> {filteredItems?.description} </p>
          <div className={GRID_LAYOUT_3}>
            {filteredItems?.categoryItems?.map((item, index) => {
              return (
                <div
                  className={`gap-5 m-0 ${diagnosedItem.includes(item.name)
                    ? 'cardlyac '
                    : 'cardly '
                    } mb-2`}
                  onClick={() => storeClick(item.name, 'diagnosed' + index)}
                  key={index}
                >
                  <div className='w-[88px] flex-col'>
                    <img
                      loading='lazy'
                      src={Allimages['diagnosed' + index]}
                      className='icongol m-auto'
                      alt={item.name}
                    />
                    <div
                      className={
                        diagnosedItem.includes(item.name) ? 'tex1ac mt-4' : 'tex1 mt-4'
                      }
                    >
                      <p>{item.name}</p>
                    </div>
                  </div>

                </div>
              )
            })}
          </div>
          <div className={NEXT_DIV_CLASS}>
            <p className='text-sm text-red-500 text-center'>{errorMsg}</p>
            <ContinueButton
              isdisabled={diagnosedItem !== ''}
              label={'Next'}
              className={'mx-auto w-9/12 !mt-3 mb-14'}
              onClick={() => { if (diagnosedItem !== 'No, I haven’t') { setPopUpModel(true) } else { handleGoForward() } }}
            />
          </div>
        </div>
      </div>
      <BottomImageModule imagepath={all_bottom_vector_image.diagonsedfireflies} image_variant={3} />

      <Popup
        open={popUpModel}
        position='center'
        className='pop-width'
        onClose={() => setPopUpModel(false)}
      >
        <div className='popup-cut-div'>
          <img src={popcutlayout} className='mt-[-12px]  m-auto' />
        </div>
        <div className='text-center'>
          {/* <div className='closeicob rounded-full w-8 h-8' onClick={() => setPopUpModel(false)}>
            &times;
          </div> */}

          <img
            loading='lazy'
            src={image ? Allimages[`${image}`] : Allimages.stethoscope}
            alt='stethoscope'
            className='imgwed !w-[8%] h-auto '
          />
          <p className='text-2xl font-medium mt-10'>
            Prioritizing your health & safety
          </p>
          {/* <span className='text-lg'>"{diagnosedItem}"</span> */}
          <p className='text-sm mt-5 font-medium'>
            If you have any medical condition, please speak to your doctor
            before you start fasting to make sure fasting is right for you.
            {diagnosedItem}
          </p>
          <div className='df2 mt-5'>

            <ContinueButton
              isdisabled={diagnosedItem !== ''}
              label={'Continue'}
              className={''}
              onClick={handleGoForward}
            />
          </div>
        </div>
      </Popup>
    </MainBg2>
  )
}
export default Page
