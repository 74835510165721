import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import { useGoToPage } from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { getPageDetails } from "./Home";
import { Allimages } from "../config/AllImage";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";
import Title1Module from "./module/Title1Module";

const Page = () => {
  const goto = useGoToPage();
  const [username, setUsername] = useState("");
  const [continueBtn, setContinueBtn] = useState(false);

  useEffect(() => {
    if (secureLocalStorage.getItem("username")) {
      setUsername(secureLocalStorage.getItem("username"));
      setContinueBtn(true);
      if (!secureLocalStorage.getItem("pageContent")) {
        getPageDetails();
      }
    }
  }, []);

  const handleChange = (event) => {
    const { value, name } = event.target;
    const regex = /^[a-zA-Z\s]+$/;
    if (name === "username") {
      if (value === "" || regex.test(value)) {
        setUsername(value.trim());
      }
    }
    if (value.trim().length > 2 && regex.test(value.trim()) && value !== "") {
      setContinueBtn(true);
    }
    if (value === "") setContinueBtn(false);
  };

  const handleGoAHead = () => {
    if (continueBtn) {
      goto("username", username, "goal");
    }
  };
  return (
    <div className="relative  sm:mt-[80px] mt-[65px]">
      <Progress progress={1} />
      <MainBg2 navbar={2} body_position={"middle"}>
        <div className={MAIN_DIV_CLASS + " what-name-main-div"}>
          <div className={TITLE_DIV_CLASS}>
            <Title1Module
              titledata={{
                title1:
                  "To fast or not to fast,that is the question But first,  @What's your name@ so we can cheer you on?",
              }}
            />
            <div className="max-w-[480px] m-auto mt-1 sm:px-10 sm:py-4 py-3 px-4 ">
              <input
                type="text"
                name="username"
                placeholder="your name"
                value={username || ""}
                onChange={handleChange}
                required
                className="inputbox mt-3 mb-5 sm:text-base border-2 border-gray-200 text-sm rounded-2xl"
              />
              <div className={NEXT_DIV_CLASS}>
                <ContinueButton
                  className={`font-medium text-xl `}
                  isdisabled={continueBtn}
                  label={"Next"}
                  onClick={handleGoAHead}
                />
              </div>
            </div>
          </div>
        </div>
        <BottomImageModule
          imagepath={all_bottom_vector_image.whatname}
          image_variant={2}
        />
      </MainBg2>
    </div>
  );
};
export default Page;
