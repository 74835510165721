import React, { useState, useEffect } from 'react'
import { useGoToPage } from '../function/functions'
import secureLocalStorage from 'react-secure-storage'
import { Allimages } from '../config/AllImage'
import { Navbar2a } from '../components/Navbar'
import { ContinueButton } from '../components/utilities'
import { MainBg2 } from '../components/Mainbg'
import { MAIN_MIDDLE_Bodly } from './module/CommonClasses'
import Footer from '../components/Footer'

const Page = () => {
  const goToPage = useGoToPage();
  const [username, setUsername] = useState('')
  const [planData, setPlanData] = useState('')
  useEffect(() => {
    if (secureLocalStorage.getItem('username')) {
      setUsername(secureLocalStorage.getItem('username'))
    }
    if (secureLocalStorage.getItem('planData')) {
      setPlanData(secureLocalStorage.getItem('planData'))
    }
  }, [])

  useEffect(() => {

    // setTimeout(() => {
    //   secureLocalStorage.clear()
    //   goToPage(null, null, '')
    // }, 5000)
  }, [])

  return (
    <div className="max-h-[100vh] h-full">
      <div body_position={'middle'} className={MAIN_MIDDLE_Bodly + "  max-w-[100%] h-[102vh]"} style={{ background: "linear-gradient(90deg, #FF9950 0, #ffffff00 30%, #ffffff00 0, #ffffff00)" }}>
        <div className={`logo flex justify-center top-0 z-30 fixed py-2 pt-8 w-full`}>

          <img
            loading="lazy"
            src={Allimages.logo}
            alt="logo"
            className="h-auto w-[192px] z-30"
            width={192}
            height={30}
          />
        </div>
        <div className=''>

          <div className=''>
            <div className='spcim1' />

            <div className='sm:flex justify-between'>
              <div className='sm:mt-0 mt-48 flex justify-center'>
                <img
                  loading='lazy'
                  src={Allimages.speaking}
                  alt='speaking'
                  className='w-[200px] sm:w-full'
                />
              </div>
              <div className='flex flex-col justify-center max-w-[400px] w-full gap-5'>

                <div className=''>
                  <h1 className='title capitalize text-2xl'>
                    Hi <b>{username}</b> Thank you for subscribing to{' '}
                    <b className=''>FastBetter.</b>{' '}
                  </h1>
                </div>
                <div className='border-2 rounded-2xl bg-[#E2F6FF] border-[#23B3F1] py-1 px-3 justify-center w-3/4 self-center'>
                  <div className='thankboxtop'>
                    <div className='thankboxtop1'>
                      <div className='thankboxico1' style={{ background: "linear-gradient(133.63deg, rgba(255, 255, 255, 0.94) -20%, #0593FC 91.67%)" }}>
                        <img
                          loading='lazy'
                          src={Allimages.fire}
                          alt='fire'
                          className='thankboxiconim'
                        />
                      </div>
                      <div style={{ paddingLeft: '5%' }} className=''>
                        <div className='text-xl'>
                          <p>{planData.name ? planData.name : '12 Months'}</p>
                        </div>
                        <div className='text-xs'>
                          <p>{planData.subTitle ? planData.subTitle : 'Billed every 12 months'}</p>
                        </div>
                      </div>
                    </div>
                    <div className='thankboxdis  bg-[#088BF5] px-2'>
                      <div className='thankboxdistex text-xs'>
                        <p>Active</p>
                      </div>
                    </div>
                  </div>
                </div>

                <ContinueButton
                  isdisabled={true}
                  label={'Begin fasting'}
                  className={'w-full mb-0'}
                  onClick={() => window.location.href = "https://user.fastbetter.com"}
                />
              </div>
            </div>

          </div>
        </div>
        <div className="fixed bottom-0 pb-4 w-full pt-2" style={{ background: "linear-gradient(90deg, #FF9950 0, #ffffff00 30%, #ffffff00 0, #ffffff00)" }}>
          <div className="flex flex-col justify-center items-center gap-1.5">
            <p className="footernewbotomtex text-gray-600 text-xs">V 1.0.0</p>
            <p className="footernewbotomtex1 text-gray-600 text-xs -mt-1.5">
              © 2024. FastBetter. All rights reserved
            </p>
          </div>
        </div>
      </div>

    </div>
  )
}
export default Page
