import { Route, Routes, BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import Home from "../pages/Home";
import Login from "../pages/Login";
import LoginVerify from "../pages/LoginVerify";
import WhatName from "../pages/WhatName";
import Goal from "../pages/Goal";
import Fasting from "../pages/Fasting";
import FastingAbout from "../pages/FastingAbout";
import Activity from "../pages/Activity";
import Nutrition from "../pages/Nutrition";
import Meals from "../pages/Meals";
import Dietary from "../pages/Dietary";
import Allergies from "../pages/Allergies";
import Dailywater from "../pages/Dailywater";
import Night from "../pages/Night";
import Weightloss from "../pages/Weightloss";
import Gender from "../pages/Gender";
import Testimonial from "../pages/Testimonial";
import Age from "../pages/Age";
import HeightWeight from "../pages/HeightWeight";
import Height from "../pages/Height";
import Weight from "../pages/Weight";
import WeightGoal from "../pages/WeightGoal";
import Motivating from "../pages/Motivating";
import Event from "../pages/Event";
import NeedLoss from "../pages/NeedLoss";
import Habits from "../pages/Habits";
import BodyType from "../pages/BodyType";
import EatingHabits from "../pages/EatingHabits";
import Emotions from "../pages/Emotions";
import AfterWeek from "../pages/AfterWeek";
import PeopleRound from "../pages/PeopleRound";
import MultiTasking from "../pages/MultiTasking";
import MyPlate from "../pages/MyPlate";
import Preliminary from "../pages/Preliminary";
import RealTalk from "../pages/RealTalk";
import Journey from "../pages/Journey";
import Ilike from "../pages/Ilike";
import MySelf from "../pages/MySelf";
import Priorities from "../pages/Priorities";
import Gainweight from "../pages/Gainweight";
import Diagnosed from "../pages/Diagnosed";
import Holistic from "../pages/Holistic";
import Goodhand from "../pages/Goodhand";
import Email from "../pages/email";
import PricePlan from "../pages/PricePlan";
import UserRegistrationForm from "../pages/UserRegistrationForm";
import Thankyou from "../pages/Thankyou";
import NotFound from "../pages/NotFound";

import DataProtectionPolicy from "../pages/DataProtectionPolicy";
import GeneralConditions from "../pages/GeneralConditions";
import Faq from "../pages/Faq";
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CookiePolicy from "../pages/CookiePolicy";
import CallBackPayment from "../pages/CallBackPayment";

const AppRoute = () => {
  return (
    <BrowserRouter basename="/">
      <Suspense fallback={"Loading..."}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/loginverfiy" element={<LoginVerify />} />

          {/*  */}
          <Route path="/what_name" element={<WhatName />} />
          <Route path="/goal" element={<Goal />} />
          <Route path="/fasting" element={<Fasting />} />
          <Route path="/fasting-about" element={<FastingAbout />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/nutrition" element={<Nutrition />} />
          <Route path="/meals" element={<Meals />} />
          <Route path="/dietary" element={<Dietary />} />
          <Route path="/allergies" element={<Allergies />} />
          <Route path="/dailywater" element={<Dailywater />} />
          <Route path="/night" element={<Night />} />
          <Route path="/weightloss" element={<Weightloss />} />
          <Route path="/gender" element={<Gender />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/age" element={<Age />} />
          {/* <Route path="/height" element={<HeightWeight />} /> */}
          <Route path="/height" element={<Height />} />
          <Route path="/weight" element={<Weight />} />
          <Route path="/weightgoal" element={<WeightGoal />} />

          <Route path="/motivating" element={<Motivating />} />
          <Route path="/event" element={<Event />} />
          <Route path="/needloss" element={<NeedLoss />} />
          <Route path="/habits" element={<Habits />} />
          <Route path="/bodytype" element={<BodyType />} />
          <Route path="/eatinghabits" element={<EatingHabits />} />
          <Route path="/emotions" element={<Emotions />} />
          <Route path="/afterweek" element={<AfterWeek />} />
          <Route path="/peopleround" element={<PeopleRound />} />
          <Route path="/multitasking" element={<MultiTasking />} />
          <Route path="/myplate" element={<MyPlate />} />
          <Route path="/preliminary" element={<Preliminary />} />
          <Route path="/realtalk" element={<RealTalk />} />
          <Route path="/journey" element={<Journey />} />
          <Route path="/i-like" element={<Ilike />} />
          <Route path="/myself" element={<MySelf />} />
          <Route path="/priorities" element={<Priorities />} />
          <Route path="/gainweight" element={<Gainweight />} />
          <Route path="/diagnosed" element={<Diagnosed />} />
          <Route path="/holistic" element={<Holistic />} />
          <Route path="/goodhand" element={<Goodhand />} />
          <Route path="/email" element={<Email />} />
          <Route path="/priceplan" element={<PricePlan />} />
          <Route path="/callback/pg/:getTranId" element={<CallBackPayment />} />
          <Route path="/user-registration" element={<UserRegistrationForm />} />
          <Route path="/thank-you" element={<Thankyou />} />

          {/* Other Page */}
          <Route
            path="/data-protection-policy"
            element={<DataProtectionPolicy />}
          />
          <Route path="/general-conditions" element={<GeneralConditions />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />

          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default AppRoute;
