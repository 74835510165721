import React, { useState, useEffect } from 'react'
import { MainBg2 } from '../components/Mainbg'
import { filterSurveyItemsByCategory, useGoToPage } from '../function/functions'
import Progress from '../components/Progress'
import { ContinueButton } from '../components/utilities'
import { useSelector } from 'react-redux'
import { Allimages } from '../config/AllImage'
import secureLocalStorage from 'react-secure-storage'
import MAIN_DIV_CLASS, { NEXT_DIV_CLASS, TITLE_DIV_CLASS } from './module/CommonClasses'
import Title1Module from './module/Title1Module'

const Page = () => {
  const { surveyItems } = useSelector(state => state.webUtilities)
  const goToPage = useGoToPage()
  const [filteredItems, setFilteredItems] = useState(null)
  let categoryType = 'your_current_priorities' // eslint-disable-next-line
  const [prioritiesItem, setPrioritiesItem] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType) // eslint-disable-next-line
      setFilteredItems(filtered[0])
    }
  }, [surveyItems, categoryType])

  const handleGoForward = () => {
    if (prioritiesItem !== '') {
      goToPage('prioritiesItem', prioritiesItem, 'gainweight')
    } else {
      setErrorMsg('Please select atleast 1 of the above')
    }
  }
  useEffect(() => {
    if (secureLocalStorage.getItem('prioritiesItem')) {
      setPrioritiesItem(secureLocalStorage.getItem('prioritiesItem'))
    }
  }, [])

  return (
    <MainBg2 navbar={2}>
      <Progress progress={93} />
      <div className={MAIN_DIV_CLASS}>
        <div className={TITLE_DIV_CLASS}>
          <div className='spcim1 my-3 ' />
          <div>
            <Title1Module titledata={filteredItems} />
            <p className='tex mt-0 mb-10'>{filteredItems?.description} </p>
          </div>

          <div className='mt-5 sm:mb-10 mb-0 gap-2 sm:px-32'>
            {filteredItems?.categoryItems?.map((item, index) => {
              return (
                <div
                  className={`p-4 ${item.name === prioritiesItem ? 'boxac !border-[#fe7701]' : 'box border border-white'
                    }`}
                  onClick={() => {
                    setPrioritiesItem(item?.name)
                    setErrorMsg('')
                  }}
                  key={index}
                >
                  <div className={`${item.name === prioritiesItem ? 'border border-orange-500 bg-[#ffede0] ' : ' bg-gray-100 border border-white'} checkbox w-12 h-8`}>
                    <img
                      loading='lazy'
                      src={
                        item.name === prioritiesItem ? './images/check.webp' : ''
                      }
                      alt={item.name === prioritiesItem ? 'check' : ''}
                    />
                  </div>
                  <p className='text-base font-normal'>{item?.name}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className={NEXT_DIV_CLASS}>
        <p className='text-sm text-red-500 text-center'>{errorMsg}</p>
        <ContinueButton
          isdisabled={prioritiesItem !== ''}
          label={'Next'}
          className={'mx-auto w-9/12 !mt-3 mb-14'}
          onClick={handleGoForward}
        />
      </div>
    </MainBg2>
  )
}
export default Page
