import axios from "axios";
import { API_BASE_URL } from "../config/ApisCollection";

const API_URL = API_BASE_URL;

export const getAxiosWithOutToken = async (config) => {
  // Data
  let data = undefined;
  if (config.data) {
    data = config.data;
  }

  // Method
  let method = "POST";
  if (config.method) {
    method = config.method;
  }

  // URL
  let URL = API_URL;
  if (config.url) {
    URL = config.url;
  }

  let response; // Declare the response variable

  // try {
  response = await axios({
    method: method,
    url: `${API_URL}/${URL}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });

  return response; // Return the response
};
