import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  storeLocalStorage,
  useGoToPage,
} from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import MAIN_DIV_CLASS, {
  GRID_LAYOUT_3,
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";
import Title1Module from "./module/Title1Module";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [goalItem, setGoalItem] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const categoryType = "whats_your_goal";

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems, categoryType]);

  const storeClick = async (itemId) => {
    setErrorMsg("");
    let updatedGoalItem = [...goalItem];
    if (updatedGoalItem.includes(`${itemId}`)) {
      updatedGoalItem = updatedGoalItem.filter((item) => item !== itemId);
    } else {
      updatedGoalItem.push(itemId);
    }
    setGoalItem(updatedGoalItem);
    storeLocalStorage("goalItem", updatedGoalItem);
  };

  useEffect(() => {
    if (secureLocalStorage.getItem("goalItem")) {
      setGoalItem(secureLocalStorage.getItem("goalItem"));
    }
  }, []);

  const handleGoForward = () => {
    if (goalItem.length > 2) {
      goToPage("goalItem", goalItem, "fasting");
    } else {
      setErrorMsg("Please select atleast 3 of the above");
    }
  };

  return (
    <MainBg2 navbar={2}>
      {filteredItems === null ? (
        <></>
      ) : (
        <>
          <Progress progress={3} />
          <div className={MAIN_DIV_CLASS}>
            <div className={TITLE_DIV_CLASS}>
              <Title1Module titledata={filteredItems} />
              <p className="tex text-xs"> {filteredItems?.description} </p>
              <div className={GRID_LAYOUT_3}>
                {filteredItems?.categoryItems?.map((item, index) => {
                  return (
                    <div
                      className={`${
                        goalItem.includes(item.name) ? "cardlyac" : "cardly "
                      } mb-2`}
                      onClick={() => storeClick(item.name)}
                      key={index}
                    >
                      <div className="w-[50px] flex-col">
                        <img
                          loading="lazy"
                          src={Allimages["goal" + index]}
                          className="icongol m-auto"
                          alt={item.name}
                        />
                        <div
                          className={
                            goalItem.includes(item.name)
                              ? "tex1ac  mt-4"
                              : "tex1 mt-4"
                          }
                        >
                          <p className="">{item.name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* left-0 fixed bottom-0  */}
              <div className={NEXT_DIV_CLASS}>
                <p className="text-sm text-red-500 text-center">{errorMsg}</p>
                <ContinueButton
                  isdisabled={goalItem.length > 2}
                  label={"Next"}
                  className={""}
                  onClick={handleGoForward}
                />
              </div>
            </div>
          </div>
          <BottomImageModule
            imagepath={all_bottom_vector_image.GoalGenie}
            image_variant={2}
          />
        </>
      )}
    </MainBg2>
  );
};
export default Page;
