import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Title1Module from "./module/Title1Module";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [Water, setWater] = useState(
    secureLocalStorage?.getItem("waterItem") || 6
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const categoryType = "what_is_your_daily_water_intake";

  useEffect(() => {
    if (secureLocalStorage.getItem("waterItem")) {
      setWater(secureLocalStorage.getItem("waterItem"));
    }
  }, []);

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    if (Water > 0) {
      goToPage("waterItem", Water, "night");
    } else {
      setErrorMsg("Please select atleast 1 of the above");
    }
  };

  return (
    <MainBg2 navbar={2}>
      {filteredItems != null ? (
        <>
          <Progress progress={23} />
          <div className={MAIN_DIV_CLASS}>
            <div className={TITLE_DIV_CLASS}>
              <Title1Module titledata={filteredItems} />
              <p className="tex text-sm"> {filteredItems?.description} </p>
              <div className="spcim1" />
              <div className="sm:mb-2 mb-1 max-w-[340px] w-full m-auto">
                {/* <div className="flex gap-3 justify-between">
              <div
                className="glsstex text-3xl bg-orange-300 p-4 rounded cursor-pointer"
                onClick={(pre) => setWater(Water > 3 ? Water - 1 : Water)}
              >
                -
              </div>
              <div className="glsstex text-6xl">{Water ? Water : 6}</div>
              <div
                className="glsstex text-3xl bg-orange-300 p-4 rounded cursor-pointer"
                onClick={(pre) => setWater(Water + 1)}
              >
                +
              </div>
            </div>*/}
                <div className=" mb-4">
                  <div className="glsstex text-6xl">{Water ? Water : 6}</div>
                  <h2 className="font-bold text-center text-sm">Glasses</h2>
                </div>

                <div className="linebotm1 -mb-[20%] mt-[22px]">
                  <img
                    loading="lazy"
                    src="./images/line.webp"
                    className=" max-h-[65px] h-full"
                    alt="line"
                  />
                </div>
                <Swiper
                  modules={[Navigation]}
                  navigation={{ clickable: true }}
                  initialSlide={Water - 1}
                  onSlideChange={(pre) => {
                    setWater(
                      filteredItems?.categoryItems[pre.activeIndex].name
                    );
                  }}
                  slidesPerView={20}
                  centeredSlides={true}
                  className=""
                >
                  <div>
                    {filteredItems?.categoryItems?.map((index, index11) => (
                      <SwiperSlide key={index11}>
                        <div key={index}>
                          <img
                            loading="lazy"
                            src="./images/linesmal.webp"
                            alt="linesmal"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </div>
                </Swiper>

                <div className="linebotm">
                  <br />
                  <img
                    loading="lazy"
                    src="./images/linebtom.webp"
                    alt="linebtom"
                  />
                </div>
              </div>
              <div className={NEXT_DIV_CLASS}>
                <p className="text-sm text-red-500 text-center">{errorMsg}</p>
                <ContinueButton
                  isdisabled={Water > 0}
                  label={"Next"}
                  className={""}
                  onClick={handleGoForward}
                />
              </div>
            </div>
          </div>
          <BottomImageModule
            imagepath={all_bottom_vector_image.dailywaterbotn}
            image_variant={3}
          />
        </>
      ) : (
        <></>
      )}
    </MainBg2>
  );
};
export default Page;
