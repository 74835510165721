import React, { useRef, useEffect, useState } from 'react'
import Loading from '../components/loading'
import { MainBg2 } from '../components/Mainbg'
import { maskEmail, useGoToPage } from '../function/functions'
import { Allimages } from '../config/AllImage'
import secureLocalStorage from 'react-secure-storage'
import { getAxiosWithOutToken } from '../axios/axiosObj'
import { Link } from 'react-router-dom'

const Page = () => {
  const [isLoading] = useState(false)
  const goto = useGoToPage()
  const [userEmail, setUserEmail] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [continueBtn, setContinueBtn] = useState(false)
  const numberOfDigits = 4
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(''))
  const otpBoxReference = useRef([])

  useEffect(() => {
    if (secureLocalStorage.getItem('email')) {
      setUserEmail(secureLocalStorage.getItem('email'))
    }
    if (secureLocalStorage.getItem('isResended')) {
      setIsResendOtp(secureLocalStorage.getItem('isResended') ? false : true)
    }
  }, [])

  const initialTotalSeconds = secureLocalStorage.getItem('remainingTime')
    ? Number(secureLocalStorage.getItem('remainingTime'))
    : 3 * 30 // Initial timer value in seconds (2 minutes)

  const [totalSeconds, setTotalSeconds] = useState(initialTotalSeconds)

  // Format the totalSeconds into HH:MM:SS
  const formatTime = seconds => {
    const minutes = Math.floor((seconds % 3600) / 60)
    const secondsRemain = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(
      secondsRemain
    ).padStart(2, '0')}`
  }

  // State to hold the formatted time string
  const [timer, setTimer] = useState(formatTime(initialTotalSeconds))
  const [isResendOtp, setIsResendOtp] = useState(false)

  useEffect(() => {
    if (!isResendOtp && secureLocalStorage.getItem('remainingTime')) {
      const interval = setInterval(() => {
        setTotalSeconds(prevSeconds => {
          if (prevSeconds === 0) {
            clearInterval(interval)
            setIsResendOtp(true)
            secureLocalStorage.removeItem('remainingTime')
            secureLocalStorage.removeItem('isResended')
            return 0
          }
          const newSeconds = prevSeconds - 1
          secureLocalStorage.setItem('remainingTime', String(newSeconds))
          secureLocalStorage.setItem('isResended', 'ture')
          return newSeconds
        })
      }, 1000)

      return () => clearInterval(interval)
    } else {
      setIsResendOtp(true)
    }
  }, [isResendOtp]) // Run this effect only once when the component mounts

  useEffect(() => {
    // Update the formatted time string whenever totalSeconds changes
    setTimer(formatTime(totalSeconds))
  }, [totalSeconds])

  const handleResendOtp = async () => {
    setIsResendOtp(false)
    if (secureLocalStorage.getItem('isResended')) {
      secureLocalStorage.removeItem('isResended')
    }
    secureLocalStorage.setItem('remainingTime', String(initialTotalSeconds)) // Reset totalSeconds
    setTotalSeconds(initialTotalSeconds) // Reset timer
    try {
      const emailResponse = await getAxiosWithOutToken({
        method: 'POST',
        url: '/pre/auth/send-otp',
        data: { email: userEmail }
      })
      if (emailResponse && emailResponse.status === 200) {
        setErrorMsg(`OTP resend to ${userEmail}`)
        setTimeout(() => {
          setErrorMsg('')
        }, 2500)
      } else {
        setErrorMsg(emailResponse.data.message)
      }
    } catch (error) {
      setErrorMsg(error.response.data.error.message || 'Something went wrong')
    }
  }

  if (isLoading) return <Loading />
  const handleLogin = async () => {
    if (!continueBtn) {
      setErrorMsg('Enter the OTP')
      return false
    }
    let otpvalue = otp.join('')
    try {
      const emailResponse = await getAxiosWithOutToken({
        method: 'POST',
        url: 'pre/auth/login-with-otp',
        data: { email: userEmail, otp: otpvalue }
      })

      if (emailResponse && emailResponse.status === 200) {
        goto(null, null, '')
      } else {
        setErrorMsg(
          emailResponse.data.message || '❌ Wrong OTP Please Check Again'
        )
      }
    } catch (error) {
      setErrorMsg(
        error.response.data.error.message || '❌ Wrong OTP Please Check Again'
      )
    }
  }

  function handleChange(value, index) {
    let newArr = [...otp]
    newArr[index] = value
    setOtp(newArr)

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus()
    }
    if (index === 3 && value === '') {
      setContinueBtn(false)
    } else if (index === 3 && value !== '') {
      setContinueBtn(true)
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus()
    }
    if (e.key === 'Enter' && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus()
    }
  }

  return (
    <MainBg2>
      <div className='w-full sm:mt-28 mt-20 mb-10'>
        <div className='imagesbotomesl1'>
          <img
            src={Allimages.leftside}
            width={340}
            height={'100%'}
            alt='homeside'
          />
        </div>
        <div className='tex1 tex12'>
          <p className='hometex sm:font-normal sm:text-base text-sm'>Intermittent Fasting</p>
          <p className='hometex sm:font-semibold sm:text-base -mt-1 text-sm'>Made Simple</p>
        </div>
        <div className='tex mt-10 mb-10'>
          <p>A 4 digit code has been sent to {maskEmail(userEmail)}</p>
        </div>
        <div className='verfiydes mb-10'>
          {otp?.map((digit, index) => (
            <input
              type='text'
              key={index}
              value={digit}
              name='verfil'
              className='verfirbox'
              maxLength={1}
              onChange={e => handleChange(e.target.value, index)}
              onKeyUp={e => handleBackspaceAndEnter(e, index)}
              ref={reference => (otpBoxReference.current[index] = reference)}
            />
          ))}
        </div>
        <div className='tex mb-6'>
          <span className='tex  text-sm'>Didn’t receive code?</span>
          {isResendOtp ? (
            <span
              className='loginbotm cursor-pointer text-sm'
              onClick={handleResendOtp}
            >
              Resend code
            </span>
          ) : (
            <span className='loginbotm cursor-not-allowed text-gray-500 text-sm'>
              Resend code({timer})
            </span>
          )}
        </div>

        <p className='text-sm text-red-500 text-center'>{errorMsg}</p>
        <div className='btmain w-11/12 mt-3 text-sm mx-auto text-center'>
          <div
            onClick={handleLogin}
            className={`sm:w-9/12 w-10/12 mx-auto font-medium text-xl bottontex bottonly sm:py-4 py-3  ${continueBtn ? 'bottonlyac' : 'bg-gray-300'
              }`}
          >
            Verify
          </div>
          <div className='tex mt-6'>
            <p className='tex font-medium text-sm'>Don’t have an account?</p>
            <Link to='/what_name' className='loginbotm text-sm font-semibold'>
              Begin your journey
            </Link>
          </div>
        </div>
      </div>
    </MainBg2>
  )
}
export default Page
