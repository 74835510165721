import React, { useState, useEffect } from 'react'
import Loading from '../components/loading'
import { MainBg2 } from '../components/Mainbg'
import { storeLocalStorage, useGoToPage } from '../function/functions'
import { Allimages } from '../config/AllImage'
import secureLocalStorage from 'react-secure-storage'
import { getAxiosWithOutToken } from '../axios/axiosObj'
import { getPageDetails } from './Home'
import { Link } from 'react-router-dom'

const Page = () => {
  const [isLoading] = useState(false)
  const goto = useGoToPage()
  const [userEmail, setUserEmail] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [continueBtn, setContinueBtn] = useState(false)
  const validateEmail = email => {
    // Regular expression for validating email addresses
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  useEffect(() => {
    if (secureLocalStorage.getItem('userEmail')) {
      setUserEmail(secureLocalStorage.getItem('userEmail'))
      setContinueBtn(true)
      if (!secureLocalStorage.getItem('pageContent')) {
        getPageDetails()
      }
    }
  }, [])

  const handleEmailChange = event => {
    const enteredEmail = event.target.value
    if (validateEmail(enteredEmail)) {
      setErrorMsg('')
      setContinueBtn(true)
      secureLocalStorage.setItem('userEmail', enteredEmail)
    } else {
      setContinueBtn(false)
      setErrorMsg('Please enter a valid email address.')
    }
    setUserEmail(enteredEmail)
  }

  if (isLoading) return <Loading />
  const handleOtp = async () => {
    if (!continueBtn) {
      setErrorMsg('Please enter your valid email address.')
      return false
    }
    try {

      const emailResponse = await getAxiosWithOutToken({
        method: 'POST',
        url: '/pre/auth/send-otp',
        data: { email: userEmail }
      })
      storeLocalStorage('email', userEmail)
      if (emailResponse && emailResponse.status === 200) {
        goto(null, null, 'loginverfiy')
      } else {
        setErrorMsg(emailResponse.data.message)
      }
    } catch (error) {
      setErrorMsg(error.response.data.error.message || 'Something went wrong')

    }
  }

  return (
    <MainBg2>
      <div className='w-full sm:mt-28 mt-20 mb-10'>
        <div className='imagesbotomesl1 '>
          <img
            src={Allimages.leftside}
            width={340}
            height={'100%'}
            alt='homeside'
          />
        </div>
        {/* <div className='text-center mb-3'>
          <h1 className='title font-medium'>Login</h1>
          <p className='font-normal text-sm'>Unlock Your Best Self</p>
        </div> */}
        <div className='tex1 tex12'>
          <p className='hometex sm:font-normal sm:text-base text-sm'>Intermittent Fasting</p>
          <p className='hometex sm:font-semibold sm:text-base -mt-1 text-sm'>Made Simple</p>
        </div>
        <input
          type='email'
          name='email'
          value={userEmail || ''}
          onChange={handleEmailChange}
          placeholder='your@email.com'
          className='inputbox mt-5 sm:text-base sm:px-4 sm:py-4 py-3 px-4 text-sm rounded-2xl border-2 border-gray-200'
        />
        <div className='cardl22l'>
          <div className='loginde' />
          <div className='tex'>
            <p className='text-sm font-medium mx-1.5'>Or login with</p>
          </div>
          <div className='loginde' />
        </div>
        <div className='cardl22l mb-6'>
          <div className='loginbox sm:mx-3 mx-4 sm:py-3 py-2.5 sm:rounded-xl'>
            <img loading='lazy' src='./images/facebook.webp' alt='facebokk' className='sm:w-3 w-2 h-auto' />
            <div className='logintex1'>
              <p className='sm:text-lg text-base'>Facebook</p>
            </div>
          </div>
          <div className='loginbox sm:mx-3 mx-4 sm:py-3 py-2.5 sm:rounded-xl'>
            <img loading='lazy' src='./images/googel.webp' className='sm:w-5 w-3 h-auto' alt='facebokk' />
            <div className='logintex1'>
              <p className='sm:text-lg text-base'>Google</p>
            </div>
          </div>
        </div>
        <p className='text-sm text-red-500 text-center'>{errorMsg}</p>
        <div className='btmain w-11/12 mt-3 mx-auto text-center'>
          <div
            onClick={handleOtp}
            className={`sm:w-8/12 w-10/12 mx-auto font-medium text-xl bottontex bottonly sm:py-4 py-3 ${continueBtn ? 'bottonlyac' : 'bg-gray-300'
              }`}
          >
            Send OTP
          </div>
          <div className='tex mt-6'>
            <p className='tex font-medium text-sm'>Don’t have an account?</p>
            <Link to='/what_name' className='loginbotm text-sm font-semibold'>
              Begin your journey
            </Link>
          </div>
        </div>
      </div>
    </MainBg2>
  )
}
export default Page
