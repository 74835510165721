import React, { useState, useEffect } from 'react'
import { MainBg2 } from '../components/Mainbg'
import {
  filterSurveyItemsByCategory,
  storeLocalStorage,
  useGoToPage
} from '../function/functions'
import secureLocalStorage from 'react-secure-storage'
import Progress from '../components/Progress'
import { ContinueButton } from '../components/utilities'
import { useSelector } from 'react-redux'
import { Allimages } from '../config/AllImage'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import MAIN_DIV_CLASS, { NEXT_DIV_CLASS, TITLE_DIV_CLASS } from './module/CommonClasses'
import Title1Module from './module/Title1Module'

const Page = () => {
  const { surveyItems } = useSelector(state => state.webUtilities)
  const goToPage = useGoToPage()
  const [motivationItem, setMotivationItem] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [filteredItems, setFilteredItems] = useState(null)

  const [selectedDate, setSelectedDate] = useState(new Date()) // Default value is today's date
  const [date, setDate] = useState('14')
  const [month, setMonth] = useState('Mar')
  const [year, setYear] = useState('2024')
  const [popUpModel, setPopUpModel] = useState(false)

  const categoryType = 'sport_event_date'

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType)
      setFilteredItems(filtered[0])
    }
  }, [surveyItems, categoryType])

  useEffect(() => {
    // Extracting date, month, and year from selectedDate
    const selectedDateObject = new Date(selectedDate)
    setDate(selectedDateObject.getDate().toString())

    // Converting numeric month to month name
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    setMonth(months[selectedDateObject.getMonth()])

    setYear(selectedDateObject.getFullYear().toString())
  }, [selectedDate])

  useEffect(() => {
    // Set default value as today's date plus 7 days
    const today = new Date()
    const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)
    setSelectedDate(nextWeek)
  }, [])

  useEffect(() => {
    if (secureLocalStorage.getItem('motivationItem')) {
      setMotivationItem(secureLocalStorage.getItem('motivationItem'))
    }
    if (secureLocalStorage.getItem('eventDate')) {
      setSelectedDate(secureLocalStorage.getItem('eventDate'))
    }
  }, [])

  const handleGoForward = () => {
    goToPage('eventDate', selectedDate, 'needloss')
  }
  const handleGoForward2 = () => {
    setSelectedDate(new Date())
    secureLocalStorage.setItem('eventDate', '')
    goToPage(null, null, 'needloss')
  }
  const today = new Date()
  const minDate = new Date(today)
  minDate.setDate(today.getDate() + 10) // 10 days after today

  const maxDate = new Date(today)
  maxDate.setFullYear(today.getFullYear() + 1) // 1 year after today

  return (
    <div className='relative'>


      <MainBg2 navbar={2} >
        <Progress progress={40} />
        <div className={MAIN_DIV_CLASS}>
          <div className={TITLE_DIV_CLASS}>
            <Title1Module titledata={filteredItems} />
            <div className='tex text-sm'>
              <p>{filteredItems?.description} </p>
            </div>
            <div className='cardll mt-2 mb-5 gap-3'>
              <div className='flex gap-7 mt-2'>
                <div className=''>
                  <span className='text-black text-4xl font-bold'>{date}</span>
                </div>
                <div className=''>
                  <span className='text-black text-4xl font-bold'>{month}</span>
                </div>
                <div className=''>
                  <span className='text-black text-4xl font-bold'>{year}</span>
                </div>
              </div>
              <div className='w-full text-center mt-1'>
                <span
                  className='uppercase font-bold text-sm cursor-pointer text-[#FE7701] !font-[work-sans-regular]'
                  onClick={() => setPopUpModel(true)}
                >
                  Select Date
                </span>
              </div>
            </div>
            <div className={NEXT_DIV_CLASS + " skip_btn "}>
              <p className='text-sm text-red-500 text-center'>{errorMsg}</p>
              <ContinueButton
                isdisabled={true}
                label={'Next'}
                className={'mx-auto w-9/12 !mt-3 !mb-2'}
                onClick={handleGoForward}
              />
              <div className='w-full text-center mb-14'>
                <button
                  className='px-3 py-1 mt-2 rounded-lg font-bold text-xs cursor-pointer text-[#000] border border-gray-200 !font-[work-sans-regular]'
                  onClick={handleGoForward2}
                >
                  Skip this step
                </button>
              </div>
            </div>
          </div>
        </div>

        <Popup
          open={popUpModel}
          position='center'
          className='pop-width'
          onClose={() => setPopUpModel(false)}
        >
          <p className='font-bold text-xl mt-1 text-center'>Selet the Date</p>
          <div className='sptop my-5 mt-3 flex justify-center'>
            <Calendar
              onChange={date => {
                setSelectedDate(date)
                setPopUpModel(false)
              }}
              className={'!border !border-gray-300'}
              minDate={minDate}
              maxDate={maxDate}
              value={selectedDate}
            />
          </div>
        </Popup>
      </MainBg2>
    </div>
  )
}
export default Page
