import allergy0 from "../../style/images/allergy/allergy0.png";
import allergy1 from "../../style/images/allergy/allergy1.png";
import allergy2 from "../../style/images/allergy/allergy2.png";
import allergy3 from "../../style/images/allergy/allergy3.png";
import allergy4 from "../../style/images/allergy/allergy4.png";
import allergy5 from "../../style/images/allergy/allergy5.png";
import allergy6 from "../../style/images/allergy/allergy6.png";
import allergy7 from "../../style/images/allergy/allergy7.png";
import allergy8 from "../../style/images/allergy/allergy8.png";
import allergy9 from "../../style/images/allergy/allergy9.png";

const allergyImages = {
  allergy0: allergy0,
  allergy1: allergy1,
  allergy2: allergy2,
  allergy3: allergy3,
  allergy4: allergy4,
  allergy5: allergy5,
  allergy6: allergy6,
  allergy7: allergy7,
  allergy8: allergy8,
  allergy9: allergy9,
};

export default allergyImages;
