import React, { useState, useEffect } from 'react'
import { MainBg2 } from '../components/Mainbg'
import {
  filterSurveyItemsByCategory,
  storeLocalStorage,
  useGoToPage
} from '../function/functions'
import secureLocalStorage from 'react-secure-storage'
import Progress from '../components/Progress'
import { ContinueButton } from '../components/utilities'
import { useSelector } from 'react-redux'
import { Allimages } from '../config/AllImage'

import { Navbar2a } from '../components/Navbar'
import MAIN_DIV_CLASS from './module/CommonClasses'
import Title1Module from './module/Title1Module'
import all_bottom_vector_image from '../config/all_bottom_vector_image'

const Page = () => {
  const { surveyItems } = useSelector(state => state.webUtilities)
  const goToPage = useGoToPage()
  const [filteredItems, setFilteredItems] = useState(null)
  const gender = secureLocalStorage.getItem('gender') || 'Male'


  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        'you_are_in_good_hands'
      )
      setFilteredItems(filtered[0])
    }
  }, [surveyItems])

  const handleGoForward = () => {
    goToPage(null, null, 'email')
  }

  const agehandelimages = () => {
    if (gender == 'Male') {
      return all_bottom_vector_image.goodhandmale;
    } else if (gender == 'Female') {
      return all_bottom_vector_image.goodhandfemale;
    } else {
      return all_bottom_vector_image.goodhandmale;
    }
  }

  return (
    <div className='manlay1 !h-a place-content-center' style={{ backgroundImage: "url(" + all_bottom_vector_image.World + ")", backgroundSize: "auto", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>
      <Navbar2a />

      <div className='goodhand-progress' >
        {/* <Progress progress={99} /> */}
      </div>


      <div className={'flex-col content-center mt-2 px-6'}>

        <div>
          <Title1Module titledata={filteredItems} />
        </div>
        <div className='imgwith mt-10'>
          <img loading='lazy' src={agehandelimages()} alt='goodhand' />
        </div>
        <div className=' flex justify-center'>
          <div className='w-[70ch] mb-12'>
            <h1 className='malestar1 mt-12 '>
              {filteredItems?.description?.split('@').map((item, index) => (
                <span
                  className={`sm:text-3xl text-2xl ${index == 1 || index == 3 ? 'titlecolo' : 'title'
                    }`}
                  key={index}
                >
                  {item}
                </span>
              ))}
            </h1>
          </div>
        </div>


      </div>
      <ContinueButton
        isdisabled={true}
        label={'Next'}
        className={''}
        onClick={handleGoForward}
      />
    </div>
  )
}
export default Page
