import { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'
import { Navbar2a } from '../components/Navbar'
import { getPageDetails } from './Home'
import { API_BASE_URL } from '../config/ApisCollection'

const DataProtectionPolicy = () => {
  const [pageContent, setPageContent] = useState('<strong>Loading....</strong>')
  const [toggleAnswer, setToggleAnswer] = useState(0)

  useEffect(() => {
    const daate = secureLocalStorage.getItem('pageContent')
    if (daate) {
      console.log(daate)
      const privacyPolicyData = daate.find(item => item.name === 'faq')
      if (privacyPolicyData) {
        setPageContent(privacyPolicyData.faqlist)
      } else {
        setPageContent('<strong>Page has not any content</strong>')
      }
    } else {
      getPageDetails()
      setPageContent('<strong>Page has not any content</strong>')
    }
  }, [secureLocalStorage])

  return (
    <div className='manlay'>
      <div className='bodylypages'>
        <Navbar2a className={'right-0'} />
        <div
          className='white-box dpp mt-16'
          style={{
            overflow: 'hidden',
            maxHeight: 'fit-content'
          }}
        >
          <div className='inner-pages-content'>
            <h1 className='t-center bottom-gap-2'>
              Frequently Asked <strong>Questions</strong>
            </h1>
            <div
              className='white-box'
              style={{
                margin: 'auto',
                overflow: 'hidden',
                maxHeight: 'fit-content'
              }}
            >
              {pageContent &&
                Array.isArray(pageContent) &&
                pageContent.map((item, index) => (
                  <div className='faq' key={index}>
                    <div className='card'>
                      <div className='card-header' id={index}>
                        <div className='mb-0'>
                          <h5
                            className='faq-title'
                            onClick={() => setToggleAnswer(index)}
                            data-target={'#' + index}
                            data-aria-expanded='false'
                            data-aria-controls={index}
                          >
                            <span className='badge'>{index+1}</span>
                            {item?.q}
                          </h5>
                        </div>
                      </div>
                      <div
                        id={index}
                        className={
                          toggleAnswer == index ? 'collapse show' : 'collapse hide'
                        }
                        aria-labelledby={index}
                        data-parent={'#' + index}
                      >
                        <div className='card-body'>
                          <p className='text-black' dangerouslySetInnerHTML={{ __html: item?.a }}></p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataProtectionPolicy
