import React from "react";

const Footer = () => {
  return (
    <div className="sm:fixed hidden bottom-0 pb-4 pt-2 w-full bg-[#fffcfb] ">
      <div className="flex flex-col justify-center items-center gap-1.5">
        <p className="footernewbotomtex text-gray-600 text-xs">V 1.0.0</p>
        <p className="footernewbotomtex1 text-gray-600 text-xs -mt-1.5">
          © 2024. FastBetter. All rights reserved
        </p>
      </div>
    </div>
  );
};
export default Footer;
