const Progress = ({ progress = 0 }) => {
  return (
    // df1 fixed mt-10 mb-10 sm:w-full flex justify-center top-20 pt-4 max-w-[320px] left-[42%]
    <div className="flex justify-center pt-1 max-w-[90px] w-full m-auto">
      <div className="barnotfil absolute rounded-2xl overflow-hidden w-[90px] ">
        <div className="barfil" style={{ width: `${progress}%` }}></div>
      </div>
      {/* <div className={`${progress === 100 ? "progressdon" : "progressun"} relative m-0 left-[21%]`}>
        <i
          className="fa fa-check"
          aria-hidden="true"
          style={{ color: "#fff" }}
        ></i>
      </div> */}
    </div>
  );
};
export default Progress;
