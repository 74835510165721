import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import secureLocalStorage from "react-secure-storage";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [filteredItems, setFilteredItems] = useState(null);
  let categoryType = "what_is_your_body_shape_people";
  const [bodyType, setBodyType] = useState("Rounded");
  const [errorMsg, setErrorMsg] = useState("");
  const gender = secureLocalStorage.getItem("gender");

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      if (secureLocalStorage.getItem("gender")) {
        if (secureLocalStorage.getItem("gender") === "Female")
          categoryType = "what_is_your_body_shape_female"; // eslint-disable-next-line
        if (secureLocalStorage.getItem("gender") === "Male")
          categoryType = "what_is_your_body_shape_male"; // eslint-disable-next-line
      }
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      setFilteredItems(filtered[0]);
    }

    if (secureLocalStorage.getItem("bodyType")) {
      setBodyType(secureLocalStorage.getItem("bodyType"));
    }
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    if (bodyType !== "") {
      goToPage("bodyType", bodyType, "eatinghabits");
    } else {
      setErrorMsg("Please select atleast 1 of the above");
    }
  };

  const agehandelimages = () => {
    if (gender == "Male") {
      return all_bottom_vector_image.bodytypemen;
    } else if (gender == "Female") {
      return all_bottom_vector_image.bodytypewomen;
    } else {
      return all_bottom_vector_image.gendercuriousbird;
    }
  };
  console.log(bodyType);
  return (
    <MainBg2 navbar={2}>
      <Progress progress={50} />
      <div className={MAIN_DIV_CLASS}>
        <div className={TITLE_DIV_CLASS}>
          <Title1Module titledata={filteredItems} />
          <div className="cardllmanbody flex mt-6 sm:mb-40 mb-6 gap-2 ">
            {filteredItems?.categoryItems?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setBodyType(item?.name);
                    secureLocalStorage.setItem("bodyType", item?.name);
                  }}
                  className="flex gap-1 mb-5 cursor-pointer"
                  key={index}
                >
                  {(gender === "Male" || gender !== "Female") && (
                    <img
                      loading="lazy"
                      src={
                        item?.name === bodyType
                          ? Allimages.manbody[index]["manbody1"]
                          : Allimages.manbody[index]["manbody0"]
                      }
                      className={`${
                        item?.name === bodyType ? "" : " non-ac-bodytype"
                      } bodyimg w-2a0`}
                      alt={item?.name}
                      onClick={() => {
                        alert("Male");
                      }}
                    />
                  )}
                  {(gender === "Female" || gender !== "Male") && (
                    <img
                      loading="lazy"
                      src={
                        item?.name === bodyType
                          ? Allimages.womanbody[index]["womanbody1"]
                          : Allimages.womanbody[index]["womanbody0"]
                      }
                      className={`${
                        item?.name === bodyType ? "" : " non-ac-bodytype"
                      } bodyimg w-2a0`}
                      alt={item?.name}
                      onClick={() => {
                        alert("Female");
                      }}
                    />
                  )}
                </div>
              );
            })}
            <div className={NEXT_DIV_CLASS}>
              <p className="text-sm text-red-500 text-center">{errorMsg}</p>
              <ContinueButton
                isdisabled={bodyType !== ""}
                label={"Next"}
                className={""}
                onClick={handleGoForward}
              />
            </div>
          </div>
        </div>
      </div>
      <BottomImageModule imagepath={agehandelimages()} image_variant={3} />
    </MainBg2>
  );
};
export default Page;
