
import journey0 from "../style/images/icons/journey/journey0.png";
import journey1 from "../style/images/icons/journey/journey1.png";
import journey2 from "../style/images/icons/journey/journey2.png";
import journey3 from "../style/images/icons/journey/journey3.png";
import journey4 from "../style/images/icons/journey/journey4.png";
import journey5 from "../style/images/icons/journey/journey5.png";

const journey_page_icons = {
    "journey0": journey0,
    "journey1": journey1,
    "journey2": journey2,
    "journey3": journey3,
    "journey4": journey4,
    "journey5": journey5,
}

export default journey_page_icons;