import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  storeLocalStorage,
  useGoToPage,
} from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import MAIN_DIV_CLASS, {
  GRID_LAYOUT_3,
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";
import BottomImageModule from "./module/BottomImageModule";
import all_bottom_vector_image from "../config/all_bottom_vector_image";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [dietaryItem, setDietaryItem] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const categoryType = "are_you_following_specific_diet";

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems, categoryType]);

  const storeClick = async (itemId) => {
    setErrorMsg("");
    setDietaryItem(itemId);
    storeLocalStorage("dietaryItem", itemId);
  };

  useEffect(() => {
    if (secureLocalStorage.getItem("dietaryItem")) {
      setDietaryItem(secureLocalStorage.getItem("dietaryItem"));
    }
  }, []);
  const handleGoForward = () => {
    if (dietaryItem.length > 0) {
      goToPage("dietaryItem", dietaryItem, "allergies");
    } else {
      setErrorMsg("Please select atleast 1 of the above");
    }
  };

  return (
    <MainBg2 navbar={2}>
      <Progress progress={21} />
      {filteredItems !== null ? (
        <>
          <div className={MAIN_DIV_CLASS}>
            <div className={TITLE_DIV_CLASS}>
              <Title1Module titledata={filteredItems} />
              <div className={GRID_LAYOUT_3}>
                {filteredItems?.categoryItems?.map((item, index) => {
                  return (
                    <div
                      className={`gap-5 ${
                        dietaryItem.includes(item.name) ? "cardlyac" : "cardly"
                      }`}
                      onClick={() => storeClick(item.name)}
                      key={index}
                    >
                      <div className="w-[88px] flex-col">
                        <img
                          loading="lazy"
                          src={Allimages["dietary" + index]}
                          className="icongol m-auto"
                          alt={item.name}
                        />
                        <div
                          className={
                            dietaryItem.includes(item.name)
                              ? "tex1ac mt-4"
                              : "tex1 mt-4"
                          }
                        >
                          <p>{item.name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={NEXT_DIV_CLASS}>
                <p className="text-sm text-red-500 text-center">{errorMsg}</p>
                <ContinueButton
                  isdisabled={dietaryItem.length > 0}
                  label={"Next"}
                  className={"mx-auto w-9/12 !mt-3 mb-14"}
                  onClick={handleGoForward}
                />
              </div>
            </div>
          </div>
          <BottomImageModule
            imagepath={all_bottom_vector_image.dietaryburden}
            image_variant={3}
          />
        </>
      ) : (
        <></>
      )}
    </MainBg2>
  );
};
export default Page;
