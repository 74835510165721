import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

export function filterSurveyItemsByCategory(surveyItems, categoryType) {
  return surveyItems.filter((item) => item.category === categoryType);
}

export const storeLocalStorage = (itemLabel, itemName) => {
  secureLocalStorage.setItem(itemLabel, itemName);
};

export const getPathPrefix = () => {
  const pathParts = window.location.pathname.split("/");
  const category = pathParts[1];

  if (category === "men") {
    return "/men/";
  } else if (category === "women") {
    return "/women/";
  } else if (category === "over40") {
    return "/over40/";
  } else {
    return "/";
  }
};

export const getPathPrefix2 = () => {
  if (window.location.href === "https://usa.fastbetter.com/") {
    return "usa";
  } else if (window.location.href === "https://uk.fastbetter.com/") {
    return "uk";
  } else if (window.location.href === "https://us.fastbetter.com/") {
    return "us";
  } else if (window.location.href === "https://ca.fastbetter.com/") {
    return "ca";
  } else if (window.location.href === "https://aus.fastbetter.com/") {
    return "aus";
  } else {
    return "us";
  }
};

export const useGoToPage = () => {
  const navigate = useNavigate();
  const goToPage = (itemLabel, itemName, page = "/") => {
    const urlPre = getPathPrefix();
    if (itemLabel) {
      secureLocalStorage.setItem(itemLabel, itemName);
    }
    navigate(urlPre + page);
  };
  return goToPage;
};

export const decrementPrev = ({ setProgressWidth }) => {
  return setProgressWidth((prev) => prev + 1);
};

export const maskEmail = (email) => {
  const atIndex = email.indexOf("@");
  if (atIndex !== -1) {
    const maskedPart = email.substring(0, 2) + "*".repeat(atIndex - 2);
    const maskedEmail = maskedPart + email.substring(atIndex);
    return maskedEmail;
  } else {
    return email; // If there's no "@" symbol, return the original email
  }
};
