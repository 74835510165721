const Title1Module = ({ titledata }) => {
  // console.log(titledata);
  return (
    <h1 className="malestar1 mt-9">
      {titledata?.title1?.split("@").map((item, index) => (
        <span
          className={`sm:text-2xl text-2xl ${
            index === 1 ? "titlecolo" : "title"
          }`}
          key={index}
        >
          {item}
        </span>
      ))}
    </h1>
  );
};
export default Title1Module;
