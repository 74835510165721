import { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'
import { Navbar2a } from '../components/Navbar'
import { getPageDetails } from './Home'

const DataProtectionPolicy = () => {
  const [pageContent, setPageContent] = useState('<strong>Loading....</strong>')

  useEffect(() => {
    const daate = secureLocalStorage.getItem('pageContent')
    if (daate) {
      const privacyPolicyData = daate.find(
        item => item.name === "privacy_policy"
      )
      if (privacyPolicyData) {
        setPageContent(privacyPolicyData.description)
      } else {
        setPageContent('<strong>Page has not any content</strong>')
      }
    } else {
      getPageDetails()
      setPageContent('<strong>Page has not any content</strong>')
    }
  }, [secureLocalStorage])

  return (
    <div className='manlay'>
      <div className='bodylypages'>
        <Navbar2a className={'right-0'} />
        <div
          className='white-box dpp mt-16'
          style={{
            overflow: 'hidden',
            maxHeight: 'fit-content'
          }}
          dangerouslySetInnerHTML={{ __html: pageContent }}
        ></div>
      </div>
    </div>
  )
}

export default DataProtectionPolicy
