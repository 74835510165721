import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import Chart from "react-apexcharts";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";
import weightlossgraph from "../../src/style/images/weight-loss-graph.png";
import fb_img_spinner from "../style/images/fb_img_spinner.gif";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [filteredItems, setFilteredItems] = useState(null);
  const categoryType = "weight_loss_graph_first";

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    goToPage(null, null, "gender");
  };
  const state = {
    options: {
      chart: {
        height: "100%",
        type: "area",
        zoom: {
          enabled: false,
          type: "x",
          autoScaleYaxis: false,
        },
      },
      // dataLabels: {
      //   enabled: true
      // },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        colors: ["#65E3D4"],
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 3,
          opacityTo: 0.9,
          stops: [0, 95, 100],
        },
      },
      dataLabels: {
        style: {
          colors: ["#65E3D4"],
        },
      },
      stroke: {
        curve: "smooth",
        colors: ["#65E3D4"],
      },
      xaxis: {
        categories: [80, 120, 160, 180],
        show: false,
      },
    },
    series: [
      {
        name: "fastbetter",
        data: [30, 25, 28, 22],
        show: false,
      },
    ],
  };
  return (
    <MainBg2 navbar={2}>
      {filteredItems === null ? (
        <>
          <img src={fb_img_spinner} />
        </>
      ) : (
        <>
          <Progress progress={28} />
          <div className={MAIN_DIV_CLASS}>
            <div
              className={TITLE_DIV_CLASS}
              style={{
                backgroundImage: "url(" + fb_img_spinner + ")",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Title1Module titledata={filteredItems} />
              <div className="df mt-10 mb-3">
                <p className="tex text-sm"> {filteredItems?.title2} </p>
                <p className="tex text-sm"> {filteredItems?.title3} </p>
              </div>
              <img
                src={weightlossgraph}
                className="w-full "
                width={620}
                height={292}
              />
              <div className="flex justify-center mt-5">
                <p className="tex text-sm sm:-mt-16 max-w-[280px] w-full">
                  {" "}
                  {filteredItems?.description}{" "}
                </p>
              </div>
              {/* <div className='grah mt-2 sm:mb-32 mb-5 cursor-none'>
            <Chart
              options={state.options}
              series={state.series}
              type='area'
              width='100%'
            />

          </div> */}

              <div className={NEXT_DIV_CLASS + " mt-10"}>
                <ContinueButton
                  isdisabled={true}
                  label={"Next"}
                  className={"mx-auto w-9/12 !mt-3 mb-14"}
                  onClick={handleGoForward}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </MainBg2>
  );
};
export default Page;
