import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import { Allimages } from "../config/AllImage";
import ProgressBar from "react-customizable-progressbar";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";
import preliminaryfastbetteruser from "../style/images/preliminary-fastbetter-user.png";
import fb_img_spinner from "../style/images/fb_img_spinner.gif";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [filteredItems, setFilteredItems] = useState(null);

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(
        surveyItems,
        "weight_goal_graph"
      );
      setFilteredItems(filtered[0]);
    }
  }, [surveyItems]);

  const handleGoForward = () => {
    goToPage(null, null, "realtalk");
  };

  return (
    <MainBg2 navbar={2}>
      <Progress progress={71} />
      <div className={MAIN_DIV_CLASS}>
        <div className={TITLE_DIV_CLASS}>
          <Title1Module titledata={filteredItems} />
          <div className="p-5 h-auto bg-transparent mt-10 mb-20">
            <div className="sm:flex sm:flex-row flex-col sm:gap-10 items-center ">
              <div className="prelim-box  max-h-[250px] h-full mb-10">
                <ProgressBar
                  radius={100}
                  progress={78}
                  strokeWidth={20}
                  segmentCount={8}
                  strokeColor="#FE7701"
                  pointerRadius={15}
                  pointerStrokeWidth={10}
                  pointerStrokeColor="#FE7701"
                  cut={180}
                  rotate={-180}
                >
                  <div className="indicator ">
                    <div>{78}%</div>
                  </div>
                </ProgressBar>
              </div>
              <div className="prelim-box max-h-[250px] h-[250px] w-[250px] p-2 mb-10">
                <img
                  src={preliminaryfastbetteruser}
                  className="sm:w-full prelim-graph  m-auto "
                />
              </div>
            </div>
          </div>

          <div className={NEXT_DIV_CLASS + " mt-10"}>
            <ContinueButton
              isdisabled={true}
              label={"Next"}
              className={"mx-auto w-9/12 !mt-3 mb-14"}
              onClick={handleGoForward}
            />
          </div>
        </div>
      </div>
    </MainBg2>
  );
};
export default Page;
