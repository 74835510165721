export const ContinueButton = ({ onClick, label, isdisabled, className }) => {
  return (
    <div className={`flex justify-center btmain w-full mt-6 mx-auto  ${className}`}>
      <div
        onClick={onClick}
        className={` bottonly text-2xl bg-gray-300 sm:py-2 py-1 rounded-2xl w-[290px] min-h-[60px] tracking-wider	 text-white ${isdisabled && 'bottonlyac !bg-[#ff9950]'}`}
      >
        {label}
      </div>
    </div>
  );
};
