import React, { useState, useEffect } from 'react'
import { MainBg2 } from '../components/Mainbg'
import { filterSurveyItemsByCategory, useGoToPage } from '../function/functions'
import secureLocalStorage from 'react-secure-storage'
import { useSelector } from 'react-redux'
import { Allimages } from '../config/AllImage'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import axios from 'axios'
import { API_BASE_URL } from '../config/ApisCollection'
import { NEXT_DIV_CLASS } from './module/CommonClasses'
import { ContinueButton } from '../components/utilities'

const Page = () => {
  const { surveyItems } = useSelector(state => state.webUtilities)
  const goToPage = useGoToPage()
  const [filteredItems, setFilteredItems] = useState(null)
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('')
  const categoryType = 'continue_priceplane'
  const weightlet = secureLocalStorage.getItem('weightGoal')
  const finalData = secureLocalStorage.getItem('finalData')
  const currency_symbols = { usd: '$', gbp: '£', cad: '$', aud: '$' }

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType)
      setFilteredItems(filtered[0])
    }
  }, [surveyItems, categoryType])

  const handleSelectPlan = plan => {
    setSelectedPlan(plan)
    setIsPopUpOpen(true)
  }
  // console.log(selectedPlan);
  const PayGlocalHandle = async event => {
    secureLocalStorage.setItem('planData', selectedPlan)
    let currentURL = window.location.href
    var url = new URL(currentURL)
    var baseUrl =
      url.protocol + '//' + url.hostname + (url.port ? ':' + url.port : '')

    let getway_api = ''
    if (event === 'stickypaypal') {
      getway_api = API_BASE_URL + '/pro/payment/stk/log/paypaloptioninitiate'
    } else {
      getway_api = API_BASE_URL + '/pro/p3/pg/initiate'
    }

    try {
      const response = await axios.post(
        getway_api,
        {
          planId: selectedPlan.id,
          callback: baseUrl + '/callback/pg'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + finalData?.accessToken
          }
        }
      )

      if (response.data.status === 200) {
        window.location.href = response.data.redirectUrl
      } else if (response.data.error) {
        alert(response.data.error.message)
      }
    } catch (error) {
      alert(error.response.data.error.message)
      console.error('Error processing payment:', error)
    }
  }

  return (
    <MainBg2 navbar={2} body_position={'top'}>
      <div className='flex items-center pt-10'>
        <div className='w-full mb-14'>
          <h1 className='malestar1 mt-9'>
            {filteredItems?.title1?.split('@').map((item, index) => (
              <span
                className={`sm:text-3xl text-2xl ${index === 1 ? 'titlecolo' : 'title'
                  }`}
                key={index}
              >
                {item}
              </span>
            ))}
          </h1>
          <div className='plnaebase h-[250px]'>
            <div className='dis topsc'>
              <p className='text-5xl font-medium'>
                {weightlet?.kg ? weightlet?.kg : weightlet?.lbs}{' '}
                {weightlet?.kg ? 'kg' : 'lb'}
              </p>
            </div>
            <p className='tex text-sm opacity-100'> {filteredItems?.description}  </p>
            <p className='tex text-sm mt-0 opacity-100'> Dec 25th, 2024  </p>
          </div>
          <div className='pricedemo flex justify-center w-full gap-10 lg:flex-row flex-col lg:absolute left-0 mt-10'>
            {finalData?.plan?.map((item, index) => {
              return (
                <div
                  className={`max-w-[380px] w-full cursor-pointer text-center  border p-2  rounded-3xl ${item?.name == selectedPlan?.name ?
                    'border-orange-500 bg-[#FEF8F4]' : 'bg-white border-gray-100'
                    }`}
                  onClick={() => handleSelectPlan(item)}
                  key={index}
                >
                  <div className='thankboxtop'>
                    <div className='flex items-center gap-1 min-w-[230px]'>
                      <div className={`${item?.name == selectedPlan?.name ? 'bg-gradient-to-r from-[#fffffff0] to-[#fe7701]' : 'bg-[#FFEDE0]'} thankboxico rounded-circle sm:max-w-12 sm:w-full sm:h-12 max-w-8 w-full h-8 flex items-center justify-between`}>
                        <img
                          loading='lazy'
                          src={Allimages.fire1}
                          alt='fire1'
                          className='thankboxiconim p-0 sm:w-6 w-3'
                        />
                      </div>
                      <div className='text-left ml-2'>
                        <p className='text-xl font-semibold'>{item?.name}</p>
                        <p className='text-xs font-normal'>{item?.subTitle}</p>
                      </div>
                    </div>
                    <div className='thankboxdis w-auto'>
                      <p className='text-xs text-white'>
                        {Math.round(
                          ((item?.price - item?.credits) / item?.price) * 100
                        )}
                        % off
                      </p>
                    </div>
                  </div>
                  <div className='thankboxpric1 border border-gray-100 rounded-2xl mt-2'>
                    <div className='thankboxprictexlay justify-center gap-3  '>
                      <span className='thankboxprictexmn text-base text-gray-400 !font-[work-sans-medium]'>
                        $ {item?.price}
                      </span>
                      <span className='thankboxprictex text-xl !font-[work-sans-bold]'>
                        $ {item?.credits}
                      </span>
                    </div>
                  </div>
                  <p className='text-sm font-medium mt-2'>
                    only ${item?.pricePerWeek} {item?.titlePerWeekDay}
                  </p>
                </div>
              )
            })}

          </div>
          <ContinueButton
            isdisabled={true}
            label={'Buy Now'}
            className={'lg:mt-[280px] mt-[40px]'}
            onClick={() => handleSelectPlan(finalData?.plan[0])}
          />
          {/* <div className={'fixed bottom-10 w-full items-center right-0 left-0 '}>
            <ContinueButton
              isdisabled={true}
              label={'Buy Now'}
              className={' mb-14'}
              onClick={() => handleSelectPlan()}
            />
          </div> */}
        </div>

      </div>

      <Popup
        open={isPopUpOpen}
        position='center'
        className='pop-width2 '
        onClose={() => setIsPopUpOpen(false)}
      >
        <div className='content py-2 p-5'>
          <div className='df spallpopam mb-2  justify-between'>
            <div className='poptit text-lg font-medium'>Select payment method</div>
            <div
              className='closeicobthak text-sm   font-medium'
              onClick={() => setIsPopUpOpen(false)}
            >
              ← Back to Plans
            </div>
          </div>
          <div className='oplin'></div>
          <div className='df mt-2'>
            <table style={{ borderBottom: '1px solid #d1d1d1', width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left' }} className='text-base font-medium' id='ketocntid'>
                    {selectedPlan?.name}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <del style={{ color: '#5bb05a' }} id='mrpid'>
                      {currency_symbols['us']}${selectedPlan?.price}
                    </del>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }} className='text-base font-medium' id='disid'>
                    Discount -
                    {Math.round(
                      ((selectedPlan?.price - selectedPlan?.credits) /
                        selectedPlan?.price) *
                      100
                    )}
                    %
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <b style={{ color: '#5bb05a' }} id='idmonth'>
                      {currency_symbols['us']}$
                      {parseInt(selectedPlan?.price) -
                        parseInt(selectedPlan?.credits)}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }} className='text-base font-medium'>Total </td>
                  <td
                    style={{ textAlign: 'right' }}
                    className='amount'
                    id='idprice'
                  >
                    <b>
                      {currency_symbols['us']}${selectedPlan?.credits}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {finalData.paymentList.paypal === true && (
            <PayPalScriptProvider
              options={{
                'client-id': finalData.clientid,
                currency: 'USD',
                vault: true
              }}
            >
              <PayPalButtons
                createBillingAgreement={async () => {
                  let payload = {
                    planId: selectedPlan.id
                  }
                  try {
                    const response = await axios.post(
                      API_BASE_URL + '/pro/p1/bagreementtoken',
                      payload,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: 'Bearer ' + finalData?.accessToken
                        }
                      }
                    )
                    if (!response.data.ok) {
                      throw new Error('Network response was not ok')
                    }
                    return response.data.token_id
                  } catch (error) {
                    console.error('Error creating billing agreement:', error)
                    throw error
                  }
                }}
                onApprove={async (data, actions) => {
                  try {
                    const response = await axios.post(
                      API_BASE_URL + '/pro/p1/billingagreementonapprove',
                      null,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: 'Bearer ' + finalData?.accessToken
                        }
                      }
                    )
                    const orderData = response.data
                    if (orderData.status === 'COMPLETED') {
                      goToPage(
                        '__orderconfirm',
                        orderData,
                        '/user-registration'
                      )
                    } else {
                      alert(orderData.status + 'Please try again...')
                    }
                  } catch (error) {
                    console.error('Error approving billing agreement:', error)
                    throw error
                  }
                }}
              />
            </PayPalScriptProvider>
          )}
          {finalData.paymentList.payglocal === true && (
            <div className='txt-cntr text-lg font-medium font'>
              <a
                className='mdlbtn pebtngrn smooth-scroll !text-white'
                onClick={() => PayGlocalHandle('payglocal')}
              >
                Debit or Credit Card{' '}
              </a>
            </div>
          )}
          {finalData.paymentList.stickypaypal === true && (
            <div className='txt-cntr text-lg font-medium font'>
              <a
                className='mdlbtn pebtngrn smooth-scroll !text-white'
                onClick={() => PayGlocalHandle('stickypaypal')}
                style={{ cursor: 'pointer' }}
              >
                PayPal
              </a>
            </div>
          )}
          <div className='oplin'></div>
          <div className='tgft-wrap1 '>
            <img src={Allimages.visa} className='sm:w-w-10 w-6' alt='visa' />
            <img src={Allimages.master} className='sm:w-w-10 w-6' alt='master' />
            <img src={Allimages.mestro} className='sm:w-w-10 w-6' alt='mestro' />
            <img src={Allimages.paypal} className='sm:w-w-10 w-6' alt='paypal' />
            <img src={Allimages.jcb} className='sm:w-w-10 w-6' alt='jcb' />
            <img src={Allimages.discover} className='sm:w-w-10 w-6' alt='discover' />
          </div>
        </div>
      </Popup>

    </MainBg2>
  )
}
export default Page
