import React, { useEffect, useState } from 'react'
import Loading from '../components/loading'
import Error from '../components/Error'
import MainBg from '../components/Mainbg'
import { useSelector } from 'react-redux'
import { filterSurveyItemsByCategory } from '../function/functions'
import { Allimages } from '../config/AllImage'
import { Link } from 'react-router-dom'
import { getAxiosWithOutToken } from '../axios/axiosObj'
import secureLocalStorage from 'react-secure-storage'
import BottomImageModule from './module/BottomImageModule'
import all_bottom_vector_image from '../config/all_bottom_vector_image'

const Page = () => {
  const { surveyItems, pageError, loading } = useSelector(
    state => state.webUtilities
  )
  const [filteredItems, setFilteredItems] = useState(null)
  const categoryType = 'home_page'

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType)
      setFilteredItems(filtered[0])
    }
  }, [surveyItems, categoryType])

  if (loading) return <Loading />
  if (pageError) return <Error />

  return (
    <MainBg>
      {filteredItems ? (
        <>
          <div className='imagesbotomesl1-cutlery left-0 top-[10%]'>
            <img
              src={all_bottom_vector_image.cutlery} className='w-full max-w-[780px]'
              alt='homeside'
            />
          </div>
          <div className='manlaynew manlaynew1'>
            <div className='bodyly1 items-center'>
              <div className='homelayout w-full'>
                {/* <div className='w-full'> */}
                <img
                  src={Allimages.logo}
                  alt='logo'
                  className='sm:w-[290px] w-[290px] sm:h-auto'
                />
                {/* </div> */}
                <div className='inter-fasting-col'>
                  <div className='tex1 tex12 mb-10 order-3	'>
                    <p className='hometex sm:font-normal sm:text-lg text-base'>Intermittent Fasting</p>
                    <p className='hometex sm:font-semibold sm:text-lg text-base '>Made Simple</p>
                  </div>
                  <div className='footernewbotom flex-col gap-5 order-2	'>
                    <Link to='/what_name'>
                      <div className='bottonlyac bottontex px-10 py-3.5 rounded-xl w-[290px] min-h-20'>
                        <p className='text-lg font-medium'>{filteredItems?.title2}</p>
                      </div>
                    </Link>
                    <a href='https://user.fastbetter.com/'>
                      <div className='bottonly bottontex px-10 py-3.5 rounded-xl border border-gray-200 bg-transparent w-[290px] min-h-20	'>
                        <p className='text-lg font-bold'>{filteredItems?.title3}</p>
                      </div>
                    </a>
                  </div>
                  <BottomImageModule imagepath={all_bottom_vector_image.selfigirl} className="order-1" />
                </div>
              </div>

            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </MainBg>
  )
}
export default Page

export const getPageDetails = async () => {
  let data = {}
  const country = secureLocalStorage.getItem('country')
  if (country) {
    data.country = country
  }
  try {
    const response = await getAxiosWithOutToken({
      method: 'POST',
      url: '/pre/general/pages',
      data: data
    })
    if (response && response.data) {
      secureLocalStorage.setItem('pageContent', response.data)
    }
  } catch (error) {
    console.log('<strong>Page has not any content</strong>')
  }
}
