import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import {
  filterSurveyItemsByCategory,
  useGoToPage,
} from "../function/functions";
import Progress from "../components/Progress";
import { ContinueButton } from "../components/utilities";
import { useSelector } from "react-redux";
import needloss from "../style/images/needloss.png";
import Chart from "react-apexcharts";
import secureLocalStorage from "react-secure-storage";
import { format } from "date-fns";
import MAIN_DIV_CLASS, {
  NEXT_DIV_CLASS,
  TITLE_DIV_CLASS,
} from "./module/CommonClasses";
import Title1Module from "./module/Title1Module";

const Page = () => {
  const { surveyItems } = useSelector((state) => state.webUtilities);
  const goToPage = useGoToPage();
  const [filteredItems, setFilteredItems] = useState(null);
  const categoryType = "weight_loss_graph";

  const weightold = secureLocalStorage.getItem("weight");
  const weightlet = secureLocalStorage.getItem("weightGoal");
  const weightType = weightold.kg ? "kg" : "lb";
  const eventlosedate = null;
  const weight_change = weightold?.kg
    ? (weightold?.kg - weightlet?.kg) / 4
    : (weightold?.lbs - weightlet?.lbs) / 8;

  const getExpectedDate = (goal_month) => {
    // Get the current date
    let currentDate = new Date();

    // Calculate the date after 5 months
    currentDate.setMonth(currentDate.getMonth() + goal_month);

    // Extract the components of the date
    let year = currentDate.getFullYear();
    let monthIndex = currentDate.getMonth();
    let day = currentDate.getDate();

    // Array of month names
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the name of the month
    let monthName = monthNames[monthIndex];

    // Return the formatted date string
    return `${monthName}, ${year}`;
  };

  useEffect(() => {
    if (surveyItems && surveyItems.length !== 0) {
      const filtered = filterSurveyItemsByCategory(surveyItems, categoryType);
      setFilteredItems(filtered[0]);
    }

    // console.log(getExpectedDate(weight_change));
  }, [surveyItems, categoryType]);

  const handleGoForward = () => {
    goToPage(null, null, "habits");
  };

  const state = {
    options: {
      chart: {
        height: "100%",
        type: "area",
        zoom: {
          enabled: false,
          type: "x",
          autoScaleYaxis: false,
        },
      },
      // dataLabels: {
      //   enabled: true
      // },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        colors: ["#65E3D4"],
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 3,
          opacityTo: 0.9,
          stops: [0, 95, 100],
        },
      },
      dataLabels: {
        style: {
          colors: ["#65E3D4"],
        },
      },

      stroke: {
        curve: "smooth",
        colors: ["#65E3D4"],
      },
      xaxis: {
        categories:
          eventlosedate == null
            ? [format(new Date(), "MMM dd")]
            : [
                format(new Date(), "MMM dd"),
                format(new Date(parseInt(eventlosedate)) * 1000, "MMM dd"),
              ],
      },
    },
    series: [
      {
        name: "fastbetter",
        data: weightold?.kg
          ? weightlet?.kg
            ? [weightold?.kg, weightlet?.kg]
            : goToPage(null, null, "height")
          : weightold?.lbs
          ? weightlet?.lbs
            ? [weightold?.lbs, weightlet?.lbs]
            : goToPage(null, null, "height")
          : goToPage(null, null, "weightgoal"),
      },
    ],
  };

  return (
    <MainBg2 navbar={2}>
      <Progress progress={43} />
      <div className={MAIN_DIV_CLASS}>
        <div className={TITLE_DIV_CLASS}>
          <Title1Module titledata={filteredItems} />
          <div className="max-w-[450px] w-full m-auto">
            <div className="df block mb-5">
              <span className="tex !text-xs font-bold">
                {filteredItems?.title2}
              </span>
              {/* <span className='tex text-xs font-bold'> {filteredItems?.title3}</span> */}
            </div>
            <div className="flex-col sm:flex-row flex gap-4 items-center">
              <div className="text-center">
                <p className="leading-snug sm:text-5xl text-4xl text-[#ff9950]">
                  {weightlet?.kg ? weightlet.kg : weightlet.lbs} {weightType}
                </p>
                <h3 className="!font-[work-sans-medium] mt-2">
                  {getExpectedDate(weight_change)}
                </h3>
                <span className="-mt-2 font-semibold text-[9px] leading-3	">
                  loose ~{" "}
                  {weightlet.kg
                    ? weightold?.kg - weightlet?.kg
                    : weightold?.lbs - weightlet?.lbs}{" "}
                  {weightType} by the competition!
                </span>
              </div>
              <div className="sm:w-8/12 w-12/12 shadow-lg shadow-gray-100	p-2 rounded-2xl mb-5 relative">
                <span className="top-0 left-2 absolute">
                  {" "}
                  {weightold?.kg ? weightold.kg : weightold?.lbs}
                  {weightType}
                </span>
                <div className="top-4 left-28 absolute">
                  <span>Vacation</span>
                  <br />
                  <span className="font-bold">
                    {" "}
                    ({weightold?.kg
                      ? weightold.kg - 4
                      : weightold?.lbs - 8}{" "}
                    {weightType})
                  </span>
                </div>
                <div className="top-14 right-2 absolute text-[#fe7701]">
                  <span>Goal</span>
                  <br />
                  <span className="font-bold">
                    {" "}
                    ({weightlet?.kg ? weightlet.kg : weightlet?.lbs}{" "}
                    {weightType})
                  </span>
                </div>
                <img src={needloss} width={300} height={255} />
                <div className="flex justify-between">
                  <span className="!font-[work-sans-medium]">Now</span>
                  <span className="!font-[work-sans-medium]">
                    {getExpectedDate(weight_change)}
                  </span>
                </div>
              </div>
            </div>
            <div className="py-5 need-loss-bt-desc">
              <p className="text-center font-medium text-sm  sm:px-4">
                {" "}
                Looking at FastBetter members like you, we predict that you’ll
                be able to confidently hit your weight loss goal by{" "}
                {getExpectedDate(weight_change)}{" "}
              </p>
              <p className="tex text-sm  sm:px-4 font-normal">
                Now, tell us a little more about your habits and lifestyle to
                see if we can help you hit your goals even faster.
              </p>
            </div>
            <div className={NEXT_DIV_CLASS}>
              <ContinueButton
                isdisabled={true}
                label={"Next"}
                className={""}
                onClick={handleGoForward}
              />
            </div>
          </div>
        </div>
      </div>
    </MainBg2>
  );
};
export default Page;
