import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Error from '../components/Error'
import { useGoToPage } from '../function/functions'
import secureLocalStorage from 'react-secure-storage'
import Navbar from '../components/Navbar'
import { API_BASE_URL } from '../config/ApisCollection'

const CallBackPayment = () => {
  const { getTranId } = useParams()
  const [errorMsg, setErrorMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)
  const goToPage = useGoToPage()
  const [timer, setTimer] = useState(5)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (timer === 0) {
      if (errorMsg) {
        goToPage(null, null, 'priceplan')
      } else {
        goToPage(null, null, 'user-registration')
      }
    }
    if (localStorage.getItem('remainingTime')) {
      localStorage.removeItem('remainingTime')
    }
  }, [timer, getTranId, goToPage])

  useEffect(() => {
    if (getTranId) {
      statusValidator(getTranId)
    } else {
      goToPage(null, null, 'priceplan')
    }
  }, [getTranId])

  const statusValidator = async tranId => {
    const get_options = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      const response = await axios.get(
        API_BASE_URL + '/pro/p3/pg/merchant/statusvalidator/web/' + tranId,
        get_options
      )

      if (response.data.status === 'COMPLETED') {
        secureLocalStorage.setItem('__orderconfirmData', response.data)
        setSuccessMsg(true)
      } else {
        setErrorMsg(response.data)
      }
      console.log(response)
    } catch (error) {
      setErrorMsg(error.response.data)
      console.error('Error validating status:', error)
    }
  }

  return (
    <div>
      <Navbar />
      <div className='bodly mt-32 text-center'>
        {errorMsg === 'Error' ? (
          <>
            <Error />
            <p style={{ color: 'black', marginTop: '60px' }}>
              --------------------Or--------------------
            </p>
            <p
              style={{
                color: 'black',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => goToPage(null, null, 'final.php')}
            >
              Try Again
            </p>
            <p
              style={{
                marginTop: '40px',
                textDecoration: 'underline',
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => setTimer(0)}
            >
              Go Back({timer})
            </p>
          </>
        ) : (
          errorMsg && (
            <main className='inner-pages'>
              <h3>{errorMsg?.status}</h3>
              {errorMsg.credits && (
                <h5>
                  Transaction Amount: {errorMsg?.currency} {errorMsg?.credits}
                </h5>
              )}
              <h3 style={{ marginTop: '10px', color: 'red' }}>
                {errorMsg?.message}
              </h3>
              <p
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                  marginTop: '60px',
                  cursor: 'pointer'
                }}
                onClick={() => goToPage(null, null, 'final.php')}
              >
                Try Again
              </p>
              <p
                style={{
                  marginTop: '40px',
                  textDecoration: 'underline',
                  color: 'blue',
                  cursor: 'pointer'
                }}
                onClick={() => setTimer(0)}
              >
                Go Back({timer})
              </p>
            </main>
          )
        )}
        {successMsg && (
          <main className='inner-pages'>
            <h3 style={{ color: 'green' }}>Payment Success</h3>
            <h3 style={{ marginTop: '10px', color: 'green' }}>
              {'Your last transaction  go through successfully.'}
            </h3>
            <p
              style={{
                marginTop: '40px',
                textDecoration: 'underline',
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => setTimer(0)}
            >
              Go Forward({timer})
            </p>
          </main>
        )}
      </div>
    </div>
  )
}

export default CallBackPayment
