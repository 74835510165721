import "./style/fast.css";
import "./style/index.css";
import "./style/App.css";
import "./style/font-awesome.min.css";
import AppRoute from "./routes/AppRoute";
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getWebUtilities, setCountry } from './store/features/webUtilitiesSlice';
import secureLocalStorage from 'react-secure-storage';
import { getPathPrefix2 } from "./function/functions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const country_code = await getPathPrefix2()
        const requestOption = {
          country: "us",
          ip: "122.176.113.48",
          location: "",
          device: "web",
          deviceVer: "",
          deviceToken: ""
        };

        const data = {
          ...requestOption,
          country: country_code
        };

        await dispatch(getWebUtilities(data));
        await dispatch(setCountry(country_code));
      } catch (error) {
        console.error("Error fetching web utilities:", error);
      }
    };
    const surveyItems = secureLocalStorage.getItem('surveyItems');
    // Check if surveyItems is undefined, null, or empty
    if (!surveyItems) {
      fetchData(); // Call fetchData only when surveyItems is undefined, null, or empty
    }
  }, [dispatch]);
  return (
    <div style={{ height: "100%" }}>
      <AppRoute />
    </div>
  );
}
export default App;
