import React from "react";
import { Allimages } from "../config/AllImage";
import backnav from '../style/images/back-nav.png';
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const history = useNavigate();
  return (
    <div className="logo flex justify-center top-0 fixed z-30 py-2 sm:pt-8 pt-8 w-full bg-[#fffcfb]">
      <div className={``}>
        <div className="absolute sm:left-10 left-3 cursor-pointer" onClick={() => { history(-1) }}>
          <img src={backnav} className="w-[40px] h-[40px] text-center" />
        </div>
        <img
          loading="lazy"
          src={Allimages.logo}
          alt="logo"
          className="h-auto sm:w-48 w-36 z-30"
          width={100}
          height={30}
        />
      </div>
    </div>
  );
}
export const Navbar2a = ({ className, backaction }) => {
  const history = useNavigate();
  return (
    <div className="">

      <div className={`logo flex justify-center top-0 z-30 fixed py-5 w-full bg-[#fffcfb] ${className}`}>
        <div className="absolute sm:left-10 left-3 cursor-pointer" onClick={() => { history(-1) }}>
          <img src={backnav} className="w-[40px] h-[40px] text-center" />
        </div>
        <img
          loading="lazy"
          src={Allimages.logo}
          alt="logo"
          className="h-auto w-[100px] z-30"
          width={100}
          height={30}
        />
      </div>
    </div>
  );
};