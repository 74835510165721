import React, { useState, useEffect } from "react";
import { MainBg2 } from "../components/Mainbg";
import { useGoToPage } from "../function/functions";
import secureLocalStorage from "react-secure-storage";
import { ContinueButton } from "../components/utilities";
import { API_BASE_URL } from "../config/ApisCollection";
import axios from "axios";

const Page = () => {
  const goToPage = useGoToPage();
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState(Date.now().toString());
  const [cpassword, setCPassword] = useState(Date.now().toString());
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [continueBtn, setContinueBtn] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (secureLocalStorage.getItem("userEmail")) {
      setUserEmail(secureLocalStorage.getItem("userEmail"));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrorMsg = { ...errorMsg }; // Copying the current error message state
    setContinueBtn(true);
    // console.log(cpassword);
    switch (name) {
      case "email":
        setUserEmail(value);
        break;
      case "password":
        setPassword(value);
        if (value.length < 6) {
          newErrorMsg.password = "Password must be at least 6 characters long.";
          setContinueBtn(false);
        } else {
          newErrorMsg.password = null;
        }
        break;
      case "cpassword":
        setCPassword(value);
        if (value !== password) {
          newErrorMsg.cpassword = "Passwords do not match.";
          setContinueBtn(false);
        } else {
          newErrorMsg.cpassword = null;
        }
        break;
      case "fname":
        setFname(value.replace(/[^a-zA-Z\s]/g, "")); // Allow only alphabets and spaces
        if (/\d/.test(value)) {
          newErrorMsg.fname = "First Name should not contain numbers.";
          setContinueBtn(false);
        } else {
          newErrorMsg.fname = null;
        }
        break;
      case "lname":
        setLname(value.replace(/[^a-zA-Z\s]/g, "")); // Allow only alphabets and spaces
        if (/\d/.test(value)) {
          newErrorMsg.lname = "Last Name should not contain numbers.";
          setContinueBtn(false);
        } else {
          newErrorMsg.lname = null;
        }
        break;
      case "phonenumber":
        setPhonenumber(value.replace(/[^0-9]/g, "")); // Allow only numbers
        break;
      default:
        break;
    }
    if (
      lname.length == 0 ||
      phonenumber.length == 0 ||
      cpassword.length == 0 ||
      password.length == 0 ||
      fname.length == 0
    ) {
      setContinueBtn(false);
    }
    setErrorMsg(newErrorMsg); // Updating the error message state
  };

  const handleGoAHead = () => {
    let newErrorMsg = { ...errorMsg }; // Copying the current error message state
    // Check if First Name is empty
    if (!fname.trim()) {
      newErrorMsg.fname = "First Name is required.";
    } else {
      newErrorMsg.fname = null; // Clear the error message if First Name is not empty
    }

    // Check if Last Name is empty
    if (!lname.trim()) {
      newErrorMsg.lname = "Last Name is required.";
    } else {
      newErrorMsg.lname = null; // Clear the error message if Last Name is not empty
    }

    // Check if Password is empty
    if (!password && !password.trim()) {
      newErrorMsg.password = "Password is required.";
    } else {
      newErrorMsg.password = null; // Clear the error message if Password is not empty
    }

    // Check if Confirm Password is empty
    if (!password && !cpassword.trim()) {
      newErrorMsg.cpassword = "Confirm Password is required.";
    } else {
      newErrorMsg.cpassword = null; // Clear the error message if Confirm Password is not empty
    }

    // Check if Phone Number is empty
    if (!phonenumber.trim()) {
      newErrorMsg.phonenumber = "Phone Number is required.";
    } else {
      newErrorMsg.phonenumber = null; // Clear the error message if Phone Number is not empty
    }

    setErrorMsg(newErrorMsg); // Updating the error message state
    // console.log(newErrorMsg);

    // If there are no errors, proceed with form submission
    if (
      !newErrorMsg.fname &&
      !newErrorMsg.lname &&
      !newErrorMsg.password &&
      !newErrorMsg.cpassword &&
      !newErrorMsg.phonenumber
    ) {
      const finalData = secureLocalStorage.getItem("finalData");

      const { accessToken } = finalData;
      let data = {
        fname: fname,
        lname: lname,
        password: password,
        confirm_password: cpassword,
        userPhone: phonenumber,
      };
      const get_options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axios
        .post(API_BASE_URL + "/pro/log/register/profile", data, get_options)
        .then((response) => {
          if (response.data.error) {
            setErrorMsg({
              message: response.data.error.message,
            });
          } else {
            setErrorMsg({
              message: response.data.message,
            });
            goToPage(null, null, "thank-you");
          }
        })
        .catch((error) => {
          setErrorMsg({
            message: error.response.data.error.message,
          });
        });
    }
  };
  return (
    <MainBg2 navbar={2}>
      <div className="flex items-center h-auto">
        <div className="w-full">
          <h1 className="title malestar1 mt-2 text-2xl  font-medium">
            Login Information
          </h1>
          <div className="mt-6">
            <label className="registelab text-sm font-medium">Your Email</label>
            <input
              type="email"
              name="email"
              placeholder="your@email.com"
              value={userEmail || ""}
              onChange={handleChange}
              required
              className="inputbox sm:text-base sm:px-3 sm:py-3 py-2 px-2 text-sm rounded-xl"
            />
            <p className="text-xs text-red-500 text-left">{errorMsg?.email}</p>
          </div>
          <div className="mt-2" hidden>
            <label className="registelab text-sm font-medium">Password</label>
            <input
              type="password"
              name="password"
              placeholder="Create Password"
              value={password || ""}
              onChange={handleChange}
              required
              className="inputbox sm:text-base sm:px-3 sm:py-3 py-2 px-2 text-sm rounded-xl"
            />
            <p className="text-xs text-red-500 text-left">
              {errorMsg?.password}
            </p>
          </div>
          <div className="mt-2" hidden>
            <label className="registelab text-sm font-medium">
              Confirm Password
            </label>
            <input
              type="password"
              name="cpassword"
              placeholder="Confirm Password"
              value={cpassword || ""}
              onChange={handleChange}
              required
              className="inputbox sm:text-base sm:px-3 sm:py-3 py-2 px-2 text-sm rounded-xl"
            />
            <p className="text-xs text-red-500 text-left">
              {errorMsg?.cpassword}
            </p>
          </div>
          <h1 className="title malestar1 mt-6 text-2xl font-medium">
            Login Information
          </h1>

          <div className="mt-2">
            <label className="registelab text-sm font-medium">First Name</label>
            <input
              type="text"
              name="fname"
              placeholder="First Name"
              value={fname || ""}
              onChange={handleChange}
              required
              className="inputbox sm:text-base sm:px-3 sm:py-3 py-2 px-2 text-sm rounded-xl"
            />
            <p className="text-xs text-red-500 text-left">{errorMsg?.fname}</p>
          </div>
          <div className="mt-2">
            <label className="registelab text-sm font-medium">Last Name</label>
            <input
              type="text"
              name="lname"
              placeholder="Last Name"
              value={lname || ""}
              onChange={handleChange}
              required
              className="inputbox sm:text-base sm:px-3 sm:py-3 py-2 px-2 text-sm rounded-xl"
            />{" "}
            <p className="text-xs text-red-500 text-left">{errorMsg?.lname}</p>
          </div>
          <div className="mt-2">
            <label className="registelab text-sm font-medium">Country</label>
            <input
              type="text"
              value={"US"}
              readOnly
              disabled
              className="inputbox sm:text-base sm:px-3 sm:py-3 py-2 px-2 text-sm rounded-xl"
            />
          </div>
          <div className="mt-2">
            <label className="registelab text-sm font-medium">
              Phone Number
            </label>
            <input
              type="tel"
              placeholder="Phone Number"
              name="phonenumber"
              value={phonenumber || ""}
              onChange={handleChange}
              required
              className="inputbox sm:text-base sm:px-3 sm:py-3 py-2 px-2 text-sm rounded-xl"
            />
            <p className="text-xs text-red-500 text-left">
              {errorMsg?.phonenumber}
            </p>
          </div>
          <p className="text-sm text-red-500 text-center">
            {errorMsg?.message}
          </p>
          <ContinueButton
            className={`font-medium text-xl !sm:mb-14 !mb-16`}
            isdisabled={continueBtn}
            label={"Continue"}
            onClick={handleGoAHead}
          />
        </div>
      </div>
    </MainBg2>
  );
};
export default Page;
