import React from 'react'
import { Allimages } from '../config/AllImage'
import { Link } from 'react-router-dom'

const Page = () => {
  return (
    <div className='manlay '>
      <div className='bodyly'>
        <div className='pagenot'>
          <div>
            <img src={Allimages.nopage} alt='notpage' className='deshimg' />
          </div>
          <div>
            <h3 className='four_zero_four_bg'>
              I cannot find the file you requested!
            </h3>
            <p className='title'>the page you are looking for not avaible!</p>
          </div>
          <Link to='/' class='link_404'>
            Go to Home
          </Link>
        </div>
      </div>
    </div>
  )
}
export default Page
